import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import voteReducer from './vote';
import modalReducer from './modal';
import gridReducer from './grid';

const store = configureStore({
  reducer: {
    user: userReducer,
    vote: voteReducer,
    modal: modalReducer,
    grid: gridReducer
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;