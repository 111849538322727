/** @jsxImportSource @emotion/react */
import { Fragment, useState } from 'react';
// Styles
import { stylesheet } from './style';
import { normalizeStyles } from 'util/helpers';
// components
import { CountdownUnit } from './countdown-unit';
import Markdown from 'components/markdown';
// State
import { useGlobalStyles } from 'store/cms';
import { useCountdown } from '@telescope/cassini-hooks';
import { Closed } from 'types';
// Hooks
import { useWidget } from '@telescope/cassini-hooks';

export interface IDisplayUnits {
  months: boolean | undefined,
  days: boolean | undefined,
  hours: boolean | undefined,
  minutes: boolean | undefined,
  seconds: boolean | undefined
}

function Countdown() {
  const { data: closedData } = useWidget({
    select: (data: Closed) => data.snapshot.snapshot_views.closed
  });
  const { content, settings, styles } = closedData!;

  const countdownContent = content.countdown_clock;
  const countdownSettings = settings.countdown_clock;

  const displayUnits = {
    months: countdownSettings.display_months,
    days: countdownSettings.display_days,
    hours: countdownSettings.display_hours,
    minutes: countdownSettings.display_minutes,
    seconds: countdownSettings.display_seconds
  };

  const endDate = new Date( countdownContent.countdown_target );
  const [ hasEnded, setHasEnded ] = useState(false);
  const onEnd = () => setHasEnded(true);

  const countdown = useCountdown(endDate, { onEnd, units: displayUnits });

  const { app, ...globalStyles } = useGlobalStyles();

  const style = stylesheet( {
    globalStyles: globalStyles,
    countdownStyles: normalizeStyles(styles)
  } );

  const countdownDescription = hasEnded? countdownContent.countdown_finished_label : countdownContent.description;

  return (
    <>

    { countdownDescription &&
      <p css={ style.countdown_label }> <Markdown copy={countdownDescription} /> </p> }

      { (!hasEnded || countdownSettings.display_timer_zero) &&
        <section css={ style.countdown_container }>

          <div css={ style.timer_wrapper } aria-live='assertive' aria-atomic='true'>

            { Object.keys(displayUnits)
              .filter((key: string) => displayUnits[key])
              .map((key: string, i: number, arr: string[]) => {
                return (
                  <Fragment key={key}>
                    <CountdownUnit styles={styles} number={countdown[key]} displayLabel={!!countdownSettings.display_labels} labels={countdownContent.labels[key]} />
                    { (i < arr.length - 1) && <span css={ style.colon }> : </span> }
                  </Fragment>
                )
              }) }

          </div>

        </section>
      }
    </>
  );
}

export default Countdown;