import { createSlice } from '@reduxjs/toolkit';

export const MODAL_TYPES = {
  auth: 'auth',
  confirmation: 'confirmation',
  errorGeneric: 'errorGeneric',
  errorOverlimit: 'errorOverlimit',
  errorWindow: 'errorWindow',
  login: 'login',
  register: 'register',
  vote: 'vote',
}

type ModalTypes = 
                  typeof MODAL_TYPES.auth |
                  typeof MODAL_TYPES.confirmation | 
                  typeof MODAL_TYPES.errorGeneric |
                  typeof MODAL_TYPES.errorOverlimit |
                  typeof MODAL_TYPES.errorWindow |
                  typeof MODAL_TYPES.login |
                  typeof MODAL_TYPES.register |
                  typeof MODAL_TYPES.vote | '';
export interface ModalState {
  type: ModalTypes;
  redirectTo?: null | ModalTypes;
}

const initialState: ModalState = {
  type: '',
  redirectTo: null
};

export const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.type = action.payload.type
      state.redirectTo = action.payload.redirectTo || null
    },
    closeModal: () => initialState
  }
});

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer;