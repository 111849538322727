import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';

import App from 'components/app';
import history from 'util/history';
import 'focus-visible';
import './style.css';

import store from 'store/store';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { PymProvider } from 'hooks/usePym';
import { WidgetProvider } from '@telescope/cassini-hooks';
import { queryClient } from 'lib';

if( window.top !== window.self ) {
  document.body.classList.add('iframed');
}

const container = document.getElementById('root');

if (!container) throw new Error ('Root element not found');

const root = createRoot(container);

root.render(
  <WidgetProvider pollingRate={5000} includeServerTimestamp={true} apiHash={window.wid}>
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PymProvider>
          <App/>
        </PymProvider>
      </Provider>
      </QueryClientProvider>
    </Router>
  </WidgetProvider>);
