/** @jsxImportSource @emotion/react */
import { useState } from 'react';
// Styles
import { normalizeStyles } from 'util/helpers';
import { stylesheet } from './style';
// Helpers
import * as constants from 'util/constants';
import { storageFactory } from 'util/storage-helpers';
import { getAppConfig } from '@telescope/cassini-utilities';
import reactStringReplace from 'react-string-replace';
// State
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { resetUser } from 'store/user';
import { openModal, MODAL_TYPES } from 'store/modal';
import { useGlobalStyles } from 'store/cms';
import { resetVoteHistory } from 'store/vote';
import { useWidget } from '@telescope/cassini-hooks';
import { Open } from 'types';

const { appHash } = getAppConfig();
const localStore = storageFactory(localStorage, appHash);

function User() {
  
  const { data: authData } = useWidget({
    select: (data: Open) => data.snapshot.snapshot_views.login_logout
  });
  const { content: { user: content }, styles: { user: styles } } = authData!;

  const dispatch = useAppDispatch();
  
  const { user, isAuthorized } = useAppSelector(state => state.user);
  
  const [ displayLogoutConfirmation, setDisplayLogoutConfirmation ] = useState(false);
  const { first_name = '', last_name = '', method, user_id } = user;

  const globalStyles = useGlobalStyles();

  const style = stylesheet({
    globalStyles: globalStyles,
    userStyles: normalizeStyles(styles)
  });

  const name = method === constants.AUTH_METHODS.EMAIL ? user_id :
    method === constants.AUTH_METHODS.FACEBOOK ? `${first_name} ${last_name}` : '';

  const handleLogoutClick = () => {
    localStore.removeItem(constants.AUTH_LOCALSTORAGE_LABEL);

    dispatch(resetUser());
    dispatch(resetVoteHistory());
    handleLogoutMessage();
  };

  const handleLoginClick = () => {
    dispatch(openModal({ type: MODAL_TYPES.auth }));
    setDisplayLogoutConfirmation(false);
  };

  const handleLogoutMessage = () => {
    setDisplayLogoutConfirmation(true);

    setTimeout( () => {
      setDisplayLogoutConfirmation(false);
    }, 1000 );
  }

  let userContent = (
    <button
      css={ style.button }
      onClick={handleLoginClick}>
      { content.login_cta }
    </button>
  );

  if ( isAuthorized ) {
    const greetingMessage = reactStringReplace(content.greeting_message, '{{USER}}', (match, i) => (
      <span>{name}</span>
    ));

    userContent = (
      <div>
        {content.greeting_message && name &&
          <p css={ style.greeting_message }> {greetingMessage} </p> }

        <button
          css={ style.button }
          onClick={handleLogoutClick}
          className="User__logout-btn"
        >
          {content.logout_cta}
        </button>
      </div>
    );
  }

  return (
    <div css={ style.user }>
      { displayLogoutConfirmation && 
        <p className="screen-reader-text" aria-live="assertive">
          { content.logout_confirmation }
        </p> }

      { userContent }
    </div>
  );

}

export default User;
