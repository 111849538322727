import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    panel_wrapper: {
      display: 'flex',
      width: '100%'
    },

    headline: {
      padding: 0,
      lineHeight: '1.1',
      marginBottom: '15px',
      ...styles.globalStyles.headlines,
      ...styles.panelStyles.headline,
      ...styles.globalStyles.headlines?.mobile,
      ...styles.panelStyles.headline?.mobile,

      [media?.tablet]: {
        ...styles.globalStyles.headlines?.tablet,
        ...styles.panelStyles.headline?.tablet
      },

      [media?.desktop]: {
        ...styles.globalStyles.headlines?.desktop,
        ...styles.panelStyles.headline?.desktop
      }
    },

    description_1: {
      width: '100%',
      ...styles.panelStyles.description_1,
      ...styles.panelStyles.description_1?.mobile,

      [media?.tablet]: {
        fontSize: '16px',
        ...styles.panelStyles.description_1?.tablet
      },

      [media?.desktop]: {
        ...styles.panelStyles.description_1?.desktop
      }
    },

    description_2: {
      width: '100%',
      ...styles.panelStyles.description_2,
      ...styles.panelStyles.description_2?.mobile,

      [media?.tablet]: {
        fontSize: '14px',
        ...styles.panelStyles.description_2?.tablet
      },

      [media?.desktop]: {
        ...styles.panelStyles.description_2?.desktop
      }
    },

    description_3: {
      width: '100%',
      ...styles.panelStyles.description_3,
      ...styles.panelStyles.description_3?.mobile,

      [media?.tablet]: {
        fontSize: '12px',
        ...styles.panelStyles.description_3?.tablet
      },

      [media?.desktop]: {
        ...styles.panelStyles.description_3?.desktop
      }
    },

    panel: {
      display: 'flex',
      backgroundColor: '#fff',
      flexDirection: 'column',
      width: '100%',
      boxShadow: '0px 1px 3px rgba(0,0,0,0.2)',
      ...styles.panelStyles.general,
      ...styles.panelStyles.general?.mobile,

      img: {
        margin: '0 auto',
        width: '100%',
        flexShrink: 0
      },

      [media?.tablet]: {
        ...styles.panelStyles.general?.tablet,
      },

      [media?.tablet]: {
        ...styles.panelStyles.general?.desktop,
      },
    },

    text_wrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      padding: '10px 10px 12px',
      textAlign: 'center',
      width: '100%',

      [media.tablet]: {
        padding: '20px 20px 22px',
      }
    },

    winner_banner: {
      width: '100%',
      padding: 10,
      marginBottom: 10,
      textAlign: 'center',
      ...styles.panelStyles.winnerBanner,
      ...styles.panelStyles.winnerBanner?.mobile,

      [media?.tablet]: {
        ...styles.panelStyles.winnerBanner?.tablet
      },

      [media?.desktop]: {
        ...styles.panelStyles.winnerBanner?.desktop
      }
    }
  }
}
