/** @jsxImportSource @emotion/react */
import { stylesheet } from './style';
import { IMG_LOADING } from 'util/constants';


function Loading() {
  const styles = stylesheet();

  return (
    <section css={ styles.loading } aria-busy='true' aria-live='polite'>

      <img css={ styles.loading_image } src={IMG_LOADING} alt="loading" />

    </section>
  );
}

export default Loading;  
