import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    ads: {
      textAlign: 'center',
    },

    adLink: {
      margin: '0'
    },

    modal : {
      paddingBottom : '25px'
    },

    mobile_rectangle: {
      backgroundColor: 'transparent',
      width: '88px',
      ...styles.adUnitStyles.default,

      [media?.tablet] : {
        display : 'none'
      }
    },

    leaderboard_wrapper: {
        display: 'none',

      [media?.tablet]: {
        display: 'block'
      }
    },

    bottom_leaderboard_wrapper: {
        display: 'none',

      [media?.tablet]: {
        display: 'block',
      }
    },


    mobile_bottom_leaderboard_wrapper: {
      ...styles.adUnitStyles.default,

      [media?.tablet]: {
        display: 'none'
      },
    },

    mobile_leaderboard: {
      ...styles.adUnitStyles.default,

      [media?.tablet]: {
        display: 'none'
      },
    },

    rectangle_wrapper: {
      display: 'none',
      marginLeft:'auto',
      [media?.tablet]: {
        display: 'block'
      }
    },

    square_wrapper: {
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      gridRow: 1,
      gridColumn: styles?.mobileColumn,

      [media?.tablet]: {
        gridColumn: styles?.tabletColumn
      },

      [media?.desktop]: {
        gridColumn: styles?.desktopColumn
      }
    },

    sponsored_by: {
      color: '#fff',
      display: 'none',
      marginBottom: '5px',
      ...styles.adUnitStyles.sponsored_by,

      [media?.tablet]: {
        display: 'block'
      }
    }
  }
}
