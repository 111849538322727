
import { ACTION_TYPES } from 'util/constants';

export const AUTH_METHODS = {
  EMAIL: 'email',
  FACEBOOK: 'fb',
  OAUTH: 'oauth'
};

export const AUTH_TYPE = {
  LOGIN: ACTION_TYPES.LOGIN,
  REGISTER: ACTION_TYPES.REGISTER
}

export const INPUT_NAMES = {
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  US_PHONE_NUMBER: 'us_phone_number',
  DATE: 'date',
  STATE: 'state',
  CHECKBOX_LEGAL1: 'optin_legal1',
  CHECKBOX_LEGAL2: 'optin_legal2',
  CHECKBOX_OPTIN1: 'optin_optin1',
  CHECKBOX_OPTIN2: 'optin_optin2'
}

export const INPUT_TYPES = {
  EMAIL: 'email',
  TEXT: 'text',
  PHONE: 'tel',
  SELECT: 'select',
  DATE: 'date',
  CHECKBOX: 'checkbox'
}

export const INPUTS_MAP = {
  [INPUT_NAMES.EMAIL]: {
    type: INPUT_TYPES.EMAIL,
    validation:  new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/),
    maxLength: 320
  },
  [INPUT_NAMES.FIRST_NAME]: {
    type: INPUT_TYPES.TEXT,
    maxLength: 320
  },
  [INPUT_NAMES.LAST_NAME]: {
    type: INPUT_TYPES.TEXT,
    maxLength: 320
  },
  [INPUT_NAMES.US_PHONE_NUMBER]: {
    type: INPUT_TYPES.PHONE,
    validation: /^[1-9]\d{2}[2-9]\d{6}$/
  },
  [INPUT_NAMES.STATE]: {
    type: INPUT_TYPES.SELECT
  },
  [INPUT_NAMES.DATE]: {
    type: INPUT_TYPES.DATE,
    validation: new RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
  },
  [INPUT_NAMES.CHECKBOX_LEGAL1]: {
    type: INPUT_TYPES.CHECKBOX
  },
  [INPUT_NAMES.CHECKBOX_LEGAL2]: {
    type: INPUT_TYPES.CHECKBOX
  },
  [INPUT_NAMES.CHECKBOX_OPTIN1]: {
    type: INPUT_TYPES.CHECKBOX
  },
  [INPUT_NAMES.CHECKBOX_OPTIN2]: {
    type: INPUT_TYPES.CHECKBOX
  },
}

// States
export const US_STATES=[{label:"Alabama",value:"AL"},{label:"Alaska",value:"AK"},{label:"American Samoa",value:"AS"},{label:"Arizona",value:"AZ"},{label:"Arkansas",value:"AR"},{label:"California",value:"CA"},{label:"Colorado",value:"CO"},{label:"Connecticut",value:"CT"},{label:"Delaware",value:"DE"},{label:"District Of Columbia",value:"DC"},{label:"Federated States Of Micronesia",value:"FM"},{label:"Florida",value:"FL"},{label:"Georgia",value:"GA"},{label:"Guam",value:"GU"},{label:"Hawaii",value:"HI"},{label:"Idaho",value:"ID"},{label:"Illinois",value:"IL"},{label:"Indiana",value:"IN"},{label:"Iowa",value:"IA"},{label:"Kansas",value:"KS"},{label:"Kentucky",value:"KY"},{label:"Louisiana",value:"LA"},{label:"Maine",value:"ME"},{label:"Marshall Islands",value:"MH"},{label:"Maryland",value:"MD"},{label:"Massachusetts",value:"MA"},{label:"Michigan",value:"MI"},{label:"Minnesota",value:"MN"},{label:"Mississippi",value:"MS"},{label:"Missouri",value:"MO"},{label:"Montana",value:"MT"},{label:"Nebraska",value:"NE"},{label:"Nevada",value:"NV"},{label:"New Hampshire",value:"NH"},{label:"New Jersey",value:"NJ"},{label:"New Mexico",value:"NM"},{label:"New York",value:"NY"},{label:"North Carolina",value:"NC"},{label:"North Dakota",value:"ND"},{label:"Northern Mariana Islands",value:"MP"},{label:"Ohio",value:"OH"},{label:"Oklahoma",value:"OK"},{label:"Oregon",value:"OR"},{label:"Palau",value:"PW"},{label:"Pennsylvania",value:"PA"},{label:"Puerto Rico",value:"PR"},{label:"Rhode Island",value:"RI"},{label:"South Carolina",value:"SC"},{label:"South Dakota",value:"SD"},{label:"Tennessee",value:"TN"},{label:"Texas",value:"TX"},{label:"Utah",value:"UT"},{label:"Vermont",value:"VT"},{label:"Virgin Islands",value:"VI"},{label:"Virginia",value:"VA"},{label:"Washington",value:"WA"},{label:"West Virginia",value:"WV"},{label:"Wisconsin",value:"WI"},{label:"Wyoming",value:"WY"}];