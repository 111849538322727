import { Stylesheet } from 'models/styles';
import { darkenColor } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    fbPermissions: {
      textAlign: 'center',
      padding: '40px'
    },

    headline: {
      ...styles.fbErrorStyles.headline
    },

    description: {
      ...styles.fbErrorStyles.description
    },

    facebook_button: {
      fontSize: '16px',
      padding: '8px',
      width: '100%',
      ...styles.buttonStyles.default,
      minHeight: '40px',
      background: 'rgb(59, 89, 153)',
      margin: '10px auto',
      color: '#fff',

      svg: {
        fill: '#fff',
        height: '20px',
        transform: 'translateY(3px)'
      },

      '> span': {
        marginRight: '15px'
      },

      ':hover': {
        background: darkenColor('rgb(59, 89, 153)'),
      },

      ':focus': {
        background: darkenColor('rgb(59, 89, 153)'),
      },
    }
  }
}
