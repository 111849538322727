import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    share: {
      padding: '25px 0',
      textAlign: 'center',

      [media?.tablet]: {
        padding: '30px 0'
      }
    },

    cta: {
      ...styles.shareStyles.cta,
      ...styles.shareStyles.cta?.mobile,

      [media?.tablet]: {
        ...styles.shareStyles.cta?.tablet
      },

      [media?.desktop]: {
        ...styles.shareStyles.cta?.desktop
      }
    },

    social_button: {
      display: 'inline-block',
      transition: 'transform .2s',
      overflow: 'visible',

      svg: {
        height: 24,
        overflow: 'visible'
      },

      ':active': {
        transform: 'scale(1.1)'
      },

      [media.hover]: {
        ':hover': {
          transform: 'scale(1.1)'
        }
      }
    },

    social_facebook: {
      svg: {
        fill: '#3B5999',
        ...styles.shareStyles.icons
      }
    },

    social_twitter: {
      marginRight: '13px',
      svg: {
        height: 22,
        fill: '#1DA1F2',
        ...styles.shareStyles.icons
      }
    }
  }
}
