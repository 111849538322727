import history from 'util/history';
import * as constants from 'util/constants';

export const navigateTo = (path: string, search: string = history.location.search)  => {
  history.push({
    pathname: path,
    search: search
  });
};

export const normalizeForUrl = (name: string) => {
  if (!name) return '';
  return name.toLowerCase().replace(/ /g, '_').replace(constants.URL_REGEX, '');
}
