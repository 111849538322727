/** @jsxImportSource @emotion/react */
// Styles
import { stylesheet } from './style';
// Helpers / Util
import * as helpers from 'util/helpers';
// State
import { Open } from 'types';
import { useWidget } from '@telescope/cassini-hooks';
export interface IAdUnitData {
  size: string;
}

function AdUnit(props: IAdUnitData) {
  const { data } = useWidget({
    select: (data: Open) => data.snapshot
  });
  const snapshot = data!;

  const { ads, snapshot_views } = snapshot;

  const { columns } = snapshot_views.grid.styles;
  const { mobile:mobileColumn = 1, tablet:tabletColumn = 2, desktop:desktopColumn = 3 } = columns;

  const { content, styles: adStyles } = ads[ `${props.size}` ];

  const styles = stylesheet({
    desktopColumn,
    tabletColumn,
    mobileColumn,
    adUnitStyles: helpers.normalizeStyles( adStyles ),
    size: props.size
  });

    return (
      <section css={[ styles.ads, styles[ `${props.size}_wrapper` ] ]}>

        {/** TODO: Check if sponsored_by is still needed */}
        { content.sponsored_by &&
          <label css={ styles.sponsored_by }>
            { content.sponsored_by }
          </label>}

        { content.link && content.image && (
          <a
            css={[ styles[ props.size ], styles.adLink ]}
            href={content.link}
            target='_blank'
            rel='noopener noreferrer'>
            <img src={content.image} alt={content.alt_text} />
          </a>
        )}

        {!content.link && content.image && (
          <div css={[ styles[ props.size ], styles.adLink ]}>
            <img
              css={ styles.adImage }
              src={content.image}
              alt={content.alt_text}
            />
          </div>
        )}
      </section>
    );
  }


export default AdUnit;
