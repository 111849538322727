import { useWidget } from '@telescope/cassini-hooks';
import { useEffect, useRef } from 'react';
import { resetVoteHistory } from 'store/vote';
import { useAppDispatch } from 'store/hooks';
import { useAppSettings } from 'store/cms';
import { Campaign } from 'types';

function isDate( timestamp: string | null ) {
  if( !timestamp ) return false;

  const date = new Date( timestamp );
  return date instanceof Date && !isNaN(date.valueOf());
}

export function useDailyVoteLimit() {
  const { data: widget } = useWidget<Campaign>();
  const dispatch = useAppDispatch();
  const { hasDailyVoteLimit, timezone } = useAppSettings();
  const yesterdayRef = useRef<string | null>( null );

  const timestamp = widget?.data?.current_timestamp;
  const localizedDateTime = new Date( timestamp ).toLocaleString('en-US', { timeZone: timezone });
  const today = new Date( localizedDateTime ).toDateString(); // date only. no time.

  useEffect( () => {
    if( hasDailyVoteLimit ) {
      if( isDate( yesterdayRef.current ) && isDate( today ) && yesterdayRef.current !== today ) {
        dispatch( resetVoteHistory() );
      }

      yesterdayRef.current = today;
    }
  }, [today, yesterdayRef, dispatch, hasDailyVoteLimit]);
};