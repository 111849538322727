import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    button: {
      color: '#808080',
      display: 'inline',
      fontSize: '16px',
      backgroundColor: 'transparent',
      margin: 0,
      verticalAlign: 'middle',
      ...styles.userStyles.link,
      ...styles.userStyles.link?.mobile,

      [media?.tablet]: {
        ...styles.userStyles.link?.tablet,
      },

      [media?.desktop]: {
        ...styles.userStyles.link?.desktop,
      }
    },

    user: {
      width: '100%',
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      ...styles.userStyles.general,
      ...styles.userStyles.general?.mobile,

      [media?.tablet]: {
        ...styles.userStyles.general?.tablet,
      },

      [media?.desktop]: {
        ...styles.userStyles.general?.desktop,
      }
    },

    greeting_message: {
      display: 'block',
      padding: 0,
      whiteSpace: 'nowrap',
      ...styles.userStyles.greetingMessage,
      ...styles.userStyles.greetingMessage?.mobile,

      '> span': {
        maxWidth: '150px',
        display: 'inline-block',
        verticalAlign: 'text-top',
        textOverflow: 'ellipsis',
        whitespace: 'nowrap',
        overflow: 'hidden',
        paddingLeft: 4
      },

      [media?.tablet]: {
        '> span': {
          maxWidth: '300px'
        },
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 15,
        ...styles.userStyles.greetingMessage?.tablet,
      },

      [media?.desktop]: {
        ...styles.userStyles.greetingMessage?.desktop,
      }
    }
  }
}
