import { Global, css } from '@emotion/react';
import { useGlobalStyles } from 'store/cms';

export default function GlobeStyles() {
  const siteWideStyles = useGlobalStyles();
  
  return (
    <Global
      styles={css`
        a {
          color: inherit;
          color: ${siteWideStyles?.links?.color}
        }

        a:hover {
          color: inherit;
          color: ${siteWideStyles?.links?.[':hover']?.color}
        }

        a:active {
          color: inherit;
          color: ${siteWideStyles?.links?.[':active']?.color}
        }
      `}
    />
  );
}

