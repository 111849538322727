/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { normalizeStyles } from 'util/helpers';
import { stylesheet } from './style';
// State
import { useGlobalStyles } from 'store/cms';
import { Campaign } from 'types';
// Components
import Markdown from 'components/markdown';
import { useWidget } from '@telescope/cassini-hooks';

function Footer() {
  const { data: footerData } = useWidget({
    select: (data: Campaign) => data.widget.global_views.footer
  });
  const { content, settings, styles } = footerData!;

  const { data: cookieSettings } = useWidget({
    select: (data: Campaign) => data.widget.settings.general.cookieConsentBanner
  });
  const { onetrustId } = cookieSettings!;

  // Styles
  const globalStyles = useGlobalStyles();

  const style = stylesheet({
    globalStyles,
    footerStyles: normalizeStyles(styles),
    settings: { displayBleed: settings.enable_background_bleed }
  });

  const getLinksList = () => {
    interface ILink {
      copy: string;
      link: string;
    }

    const links = content.links || [];

    return links.map((footerLink) => {
      const { copy, link } = footerLink;

      return link && (
        <React.Fragment key={link}>
          <a href={link}
            css={ style.footerLink }
            rel="noopener noreferrer"
            target="_blank"
            className="Footer__text-link">
            <Markdown copy={copy} />
          </a>
          <span css={ style.separator }> | </span>
        </React.Fragment>
      );
    } );
  };

  const getFollowLinksList = () => {
    interface IFollowLink {
      icon: string;
      link: string;
      alt_text: string;
    }

    const links = content.follow_links || [];

    return links.map( ( followLink: IFollowLink ) => {
      const { icon, link, alt_text } = followLink;

      return link && (
        <React.Fragment key={link}>
          <a href={link}
            css={ style.followLink } 
            rel="noopener noreferrer"
            target="_blank"
            className="Footer__follow-link">
              <img src={icon} alt={alt_text} />
          </a>
        </React.Fragment>
      );
    } );
  };

  return (
    <footer css={ style.footer } role='contentinfo'>
      <div css={ style.container }>

        <div css={ style.followLinksContainer }>
          { getFollowLinksList() }
        </div>

        <div css={ style.linksContainer }>
          { getLinksList() }

          { !!onetrustId && 
            <button className="ot-sdk-show-settings Footer__text-link" css={ style.footerLink }>
              Cookie Settings
            </button> }
        </div>

        { content.copy &&
          <p css={ style.copy }> <Markdown copy={content.copy} /> </p> }

      </div>
    </footer>
  );

}

export default Footer;
