/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
// Hooks
import { useForm } from 'react-hook-form';
// Helpers
import { AuthSubmitFn, RenderInputFn } from '.';
import { AUTH_TYPE, AUTH_METHODS } from './constants';
import { InputFieldData, AuthError, AuthTypes, AuthMethods } from './types';
import * as fbHelpers from 'util/fb-helpers';
import reactStringReplace from 'react-string-replace';
// Styles
import { stylesheet } from './style';
import { normalizeStyles } from 'util/helpers';
// State
import { useGlobalStyles } from 'store/cms';
import { useAppSelector } from 'store/hooks';
// Components
import { FacebookIcon } from 'util/icons';
import { LoadingSpinner } from 'components/spinner';
import Markdown from 'components/markdown';

export interface ILoginProps {
  SwapError: React.FC;
  content: Record<string,any>
  styles: Record<string,any>
  authMethods: Record<string,any>
  method: null | string
  defaultValues: Record<string,any>
  hasRegistration: boolean
  hasAuthTypeError: boolean
  authMethodError: AuthError
  isLoading?: AuthMethods,
  setDefaultValues: (values: Record<string,any>) => void
  updateAuthMethod: (method: string) => void
  updateAuthType: (method: AuthTypes) => void
  renderInput: RenderInputFn
  onSubmit: AuthSubmitFn
}

const Login = ({ SwapError, content, styles, authMethods, method, isLoading,
                 updateAuthMethod, updateAuthType, renderInput, onSubmit, 
                 defaultValues, setDefaultValues, hasRegistration, hasAuthTypeError, authMethodError } : ILoginProps )=> {

  const { user, ...auth } = useAppSelector(state => state.user);

  // Form
  const requiredOptins = content.optins.some((input: any) => {
    return input.required;
  });

  const { handleSubmit, getValues, setValue, control, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: true
  });

  // Set values for prechecked optins
  useEffect(() => {
    content.optins?.forEach((input: InputFieldData) => {
      if (input.prechecked) {
        setValue(input.field_type, input.prechecked);
      }
    });
  }, [ content.optins ]);

  // Methods
  const hasMultipleMethods = Object.keys(authMethods).filter((k) => authMethods[k]).length > 1;

  const onMethodClick = (selectedMethod: string) => {
    if (selectedMethod === method) {
      onFormSubmit();
    } else {
      updateAuthMethod(selectedMethod)
    }
  }

  useEffect(() => {
    if (method) {
      onFormSubmit();
    }
  }, [ method ]);

  // Submit
  const onFormSubmit = useCallback(() => {
    handleSubmit((data: Record<string,any>) => {
      onSubmit(data);
    })();
  }, [ method, handleSubmit ]);

  //
  const toggleAuthType = () => {
    const values = getValues();
    setDefaultValues(values);
    updateAuthType(AUTH_TYPE.REGISTER);
  }

  // Styles
  const loginStyles = normalizeStyles(styles);
  const globalStyles = useGlobalStyles();
  const style = stylesheet( {
    globalStyles: globalStyles,
    authStyles: loginStyles,
    buttonStyles: loginStyles.buttons,
    isLoading
  });

  if ( auth.hasError === fbHelpers.FB_ERROR_TYPE.PERMISSIONS || auth.hasError === fbHelpers.FB_ERROR_TYPE.GENERIC ) {
    return <SwapError />;
  }

  return (
    <div aria-label='Log in' css={style.authModal}>

      { content.headline &&
        <h2 css={style.headline}> <Markdown copy={content.headline} /> </h2> }
      
      <form css={style.loginForm} onSubmit={(e: React.SyntheticEvent) => e.preventDefault()}>
        { content.optins[0]?.field_type &&
          <div css={[ style.optinsContainer, style.formSection ]}>
            { content.optins.map(((el: any) => <React.Fragment key={el.field_type}> { 
              renderInput({ data: el, hasError: !!errors[el.field_type], styles: loginStyles, control })
            } </React.Fragment>))}
          </div> }
        
        <div css={[ style.methodsContainer, style.formSection ]}>
          { hasMultipleMethods && content.sign_in_options &&
            <h3 css={style.optionsLabel}> <Markdown copy={content.sign_in_options} /> </h3> }

          { authMethods[AUTH_METHODS.FACEBOOK] &&
            <>
              <button type="button"
                css={[ style.button, style.facebookButton ]}
                onClick={() => { onMethodClick(AUTH_METHODS.FACEBOOK) }}>
                <FacebookIcon />
                { content.buttons.facebook }
                { isLoading === AUTH_METHODS.FACEBOOK && <LoadingSpinner position='inline' size={20} /> }
              </button> 

              { authMethodError?.method === AUTH_METHODS.FACEBOOK &&
                <p css={ style.errorMessage }> { authMethodError.message } </p> }
            </> }

          { authMethods[AUTH_METHODS.OAUTH] &&
            <>
            <button type="button"
              css={[ style.button, style.oauthButton ]}
              onClick={() => { onMethodClick(AUTH_METHODS.OAUTH) }}>
              { content.buttons.oauth }
              { isLoading === AUTH_METHODS.OAUTH && <LoadingSpinner position='inline' size={20} /> }
            </button> 
            
            { authMethodError?.method === AUTH_METHODS.OAUTH &&
              <p css={ style.errorMessage }> { authMethodError.message } </p> }
          </> }

          { hasMultipleMethods && authMethods[AUTH_METHODS.EMAIL] &&
            <div css={style.divider}>
              <span css={style.divider_line} />
              <span css={style.divider_text}> OR </span>
              <span css={style.divider_line} />
            </div> }

          <div css={style.formSection}>

            { content.email_login_headline &&
              <p css={style.optionsLabel}> <Markdown copy={content.email_login_headline} /> </p> }

            {( authMethods[AUTH_METHODS.EMAIL] ) &&
              <div css={style.formSection}>
                { renderInput({ data: content.email_input, hasError: !!errors[content.email_input.field_type], styles: loginStyles, control }) }
              </div> }

          { authMethods[AUTH_METHODS.EMAIL] &&
            <>
              <button type="button"
                css={[ style.button, style.email_button ]}
                onClick={() => { onMethodClick(AUTH_METHODS.EMAIL) }}>
                { content.buttons.email }
                { isLoading === AUTH_METHODS.EMAIL && <LoadingSpinner position='inline' size={20} /> }
              </button> 

              { authMethodError?.method === AUTH_METHODS.EMAIL &&
              <p css={ style.errorMessage }> { authMethodError.message } </p> }
            </> }

            { hasRegistration && 
              <button type="button" onClick={ toggleAuthType } css={style.authTypeLink}> { content.link_to_registration } </button> }

            { hasAuthTypeError && 
              <p css={style.errorMessage}> { reactStringReplace(content.error_message.message, '{{LINK}}', (_, i) => (
                <button onClick={ toggleAuthType } key={i}> {content.error_message.link_text} </button> ))} </p> }
            
            { content.required_fields_label &&
             ((content.email_input.required && method === AUTH_METHODS.EMAIL) || requiredOptins) &&
              <span css={style.requiredLabel}> {content.required_fields_label} </span> }

          </div>

        </div>

      </form>
    </div>
  )
}

export default Login;