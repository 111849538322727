/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from 'react';
// Styles
import { stylesheet } from './style';
import { getRulesetParameters, normalizeStyles } from 'util/helpers';
// Helpers
import { useRouteMatch } from 'react-router-dom';
import * as constants from 'util/constants';
import * as models from 'models/index';
import { IContestant } from 'models/cms';
import { isEmpty } from 'util/helpers';
import { normalizeForUrl, navigateTo } from 'util/route-helpers';
import { Open } from 'types';
import { SortingDirection } from 'types';
// Components
import Panels from 'components/panels/index';
import Markdown from 'components/markdown';
// Hooks
import { useScroll } from 'hooks/useScroll';
// State
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useCategories, useContestants, useAppSettings } from 'store/cms';
import { setVoteHistory, setCategoryId, setContestantId, useCategory, useContestant } from 'store/vote';
import { openModal } from 'store/modal';
import { setSortingDirection } from 'store/grid';
import { MODAL_TYPES } from 'store/modal';
// Context
import { useGlobalStyles } from 'store/cms';
import { useVote } from '@telescope/cassini-hooks';
import { useWidget } from '@telescope/cassini-hooks';

type IParams = {
  category?: string;
  contestant?: string;
}

function Grid(props: models.global.IGenericObject) {

  const { data: gridData } = useWidget({
    select: (data: Open) => data.snapshot.snapshot_views.grid
  });
  const { content, settings, styles } = gridData!;

  const { data: voteSettings } = useWidget({
    select: (data: Open) => getRulesetParameters( data )
  })

  const dispatch = useAppDispatch();

  const contestants = useContestants();
  const { user, ...auth } = useAppSelector(state => state.user);
  const voteHistory = useAppSelector(state => state.vote.history);
  const historyFetched = useAppSelector(state => state.vote.historyFetched);
  const category = useCategory();
  const contestant = useContestant();
  const categories = useCategories();
  const modal = useAppSelector(state => state.modal);

  const sortingDirection = useAppSelector(state => state.grid.sortingDirection);
  const { isCategoryVote } = useAppSettings();

  const scrollToTop = useScroll();
  
  // Route
  const routePath = isCategoryVote? `/:category?/:contestant?` : `/:contestant?`;
  const match = useRouteMatch(routePath);
  const params: IParams = match?.params || {};

  const displayRemainingVotes = voteHistory && auth.isAuthorized && settings.display_vote_count;

  // Api
  const getVoteHistory = useVote();
  
  // Styles
  const globalStyles = useGlobalStyles();
  const gridStyles = normalizeStyles(styles);
  const { columns } = gridStyles;

  const style = stylesheet({
    columns: {
      mobile: ( parseInt( columns?.mobile ) > constants.COLUMNS.MOBILE ) ? constants.COLUMNS.MOBILE : columns?.mobile,
      tablet: ( parseInt( columns?.tablet ) > constants.COLUMNS.TABLET ) ? constants.COLUMNS.TABLET : columns?.tablet,
      desktop: ( parseInt( columns?.desktop ) > constants.COLUMNS.DESKTOP ) ? constants.COLUMNS.DESKTOP : columns?.desktop
    },
    globalStyles: globalStyles,
    gridStyles: gridStyles
  });

  useEffect(() => {
    const sortDirection = sortingDirection || settings.sorting_direction;
    
    if (sortingDirection !== sortDirection) {
      dispatch(setSortingDirection( sortDirection ));
    }
  }, [ sortingDirection, settings.sorting_direction ,dispatch ]);

  const handleParams = useCallback(() => {
    // Handle category param
    let selectedCategory = !isEmpty(category)? category : categories[0];
    
    if (isCategoryVote && params.category && (params.category !== category.category_title)) {

      const categoryByParam = categories.find( cat => normalizeForUrl( cat.category_title ) === params.category );

      // If no categories are found, send user back to landing page
      if (!categoryByParam?.category_key) {
        navigateTo('/');
        return;
      } 

      selectedCategory = categoryByParam;
      dispatch(setCategoryId(selectedCategory.category_key));
    }
    
    if (!isCategoryVote) {
      dispatch(setCategoryId(selectedCategory.category_key));
    }

    if ((params.contestant === normalizeForUrl(contestant?.name)) || (modal.type && modal.type !== MODAL_TYPES.vote)) return;

    // Handle contestant param
    if (!params.contestant) {
      dispatch(setContestantId(''));
      return;
    }

    const contestantByParam = selectedCategory.vote_choices.find(( contestant: IContestant ) => normalizeForUrl(contestant.name) === params.contestant );
    // If no contestant found, send user back to landing page

    if (!contestantByParam?.id) {
      navigateTo('/');
      return;
    } 

    dispatch(setContestantId(contestantByParam.id));
    dispatch(openModal({ type: MODAL_TYPES.vote }));

  }, [ params.category, params.contestant, contestant?.id, categories, dispatch, category, isCategoryVote, modal.type ]);

  const updateVoteHistory = useCallback(async () => {
    try {
      const response = await getVoteHistory({ 
        category: category?.category_key,
        method: user.method,
        user_id: user.user_id,
        action_type: constants.ACTION_TYPES.GET
      }, auth.authorization);

      let history = {};

      if (response && response.response_code === constants.RESPONSE_CODES.VALID) {
        history = response.votestring ? { ...JSON.parse(response.votestring) } : {};
      }

      dispatch(setVoteHistory(history));

    } catch (error) {
      // TODO: handle voteapi error
      console.error('Vote history failed ', error);
    }
  // TODO: ts lint complains about not having getVoteHistory as a dependency.
  // Having it will cause multiple re-renders.
  // Before adding getVoteHistory as a dependency, fix useVote to prevent extra rendering 
  }, [ category.category_key, user.method, user.user_id, dispatch, getVoteHistory ]);

  useEffect(() => {
    // We need to remove the contestant param from the url when a modal closes. 
    if (modal.type || !params.contestant || !contestant?.id) return;
    
    const pathname = isCategoryVote ? `/${normalizeForUrl(category.category_title)}` : `/`;
    navigateTo(pathname);

  }, [ modal.type, contestant?.id, params.contestant, isCategoryVote, category.category_title ]);

  // Effects
  useEffect(() => {
    handleParams();
  }, [ params.category, params.contestant, handleParams ]);

  useEffect(() => {
    let name = '';

    if( params.category ) {
      name = params.category + '/';
    }

    if( params.contestant ) {
      name += params.contestant;
    }

    // TODO: check if category / contestant page is firing
    // googleHelpers.trackGooglePage(name);
    scrollToTop();
  }, [ params.category, params.contestant, scrollToTop ]);

  useEffect(() => {
    if (auth.isAuthorized && category.category_key && !historyFetched) {
      updateVoteHistory();
    }
  }, [ auth.isAuthorized, category.category_key, updateVoteHistory, historyFetched ]);

  const handleSortingClick = async (sortingDirection: SortingDirection) => {
    dispatch(setSortingDirection( sortingDirection ));
  }

  const renderRemainingVotes = () => {
    const total = voteHistory.total || 0;

    const maxVotes = voteSettings.category_vote_limit;
    const remainingVotes = maxVotes - total < 0 ? 0 : maxVotes - total;

    const copyKey = remainingVotes === 1 ? 'singular' : 'plural';
    const countCopy = content.vote_count_label[ copyKey ];

    return ( 
      <p css={ style.countLabel } key={category.category_key}> 
        <span css={ style.count } key={`count-${category.category_key}`}>{remainingVotes.toString() }</span>
        <span> { countCopy } </span>
      </p> )
  }

  const renderSortingOptions = () => {
    const randomCls =
      sortingDirection === SortingDirection.DESCENDING
        ? style.sort_button_active
        : style.sort_button_inactive;
    const alphabeticalCls =
      sortingDirection === SortingDirection.ASCENDING
        ? style.sort_button_active
        : style.sort_button_inactive;

    return (
      <div css={[ style.sort_wrapper, style.sort_button ]}>
        <span css={ style.sort_label }>Sort: </span>
        <button
          aria-label='Alphabetical sort'
          aria-pressed={sortingDirection === SortingDirection.ASCENDING}
          onClick={() => {
            handleSortingClick( SortingDirection.ASCENDING );
          }}
          css={ alphabeticalCls }
        >
          A-Z
        </button>
        <span aria-hidden='true'> | </span>
        <button
          aria-label='Random sort'
          aria-pressed={sortingDirection === SortingDirection.DESCENDING}
          onClick={() => {
            handleSortingClick( SortingDirection.DESCENDING );
          }}
          css={ randomCls }
        >
          Z-A
        </button>
      </div>
    );
  };

  return (
    <section css={ style.grid }>

      <div css={ style.text_wrapper } >

        <div aria-live="polite" aria-atomic="true" role="region">
          <h1 css={ style.headline }>
            <Markdown copy={isCategoryVote ? category.category_title : content.headline}/>
          </h1>
        </div>

        { category.description_1 &&
            <h2 css={ style.cat_description }> <Markdown copy={category.description_1} /> </h2> }
        
        { ( content.additional_info || displayRemainingVotes) &&
        <div css={ style.info_container }>

          { content.additional_info &&
          <p css={ style.additional_info }> 
            <Markdown copy={content.additional_info} />

            { displayRemainingVotes && <span css={ style.info_separator }> | </span> }
          </p> }

          { displayRemainingVotes && renderRemainingVotes() }
        </div>  }

        <div css={ style.subheadline_wrapper }>
          <p css={ style.description }>
            <Markdown copy={content.description} />
          </p>

          { settings.display_sorting_methods && renderSortingOptions() }

        </div>

      </div>
    
      { category.vote_choices && 
        <section css={ style.panel_window }>

          { contestants?.length &&
          <Panels contestants={contestants} />  }

          {props.children}
        </section> }

    </section>
  );
}

export default Grid;
