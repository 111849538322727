import pym from 'pym.js';
import { createContext, ReactNode, useContext } from 'react';

type PymContextValue = undefined | pym.Child | null;

const PymContext = createContext<PymContextValue>( undefined );

interface PymProviderProps {
  children: ReactNode
}

export function PymProvider({ children }: PymProviderProps) {
  // @TODO: fix types
  let child: any = null;

  if( window.top !== window.self ) {
    child = new pym.Child({
      polling: 500
    });
  };

  return (
    <PymContext.Provider value={ child }>
      { children }
    </PymContext.Provider>
  )
}

export function usePym() {
  const child = useContext( PymContext );

  if( child === undefined ) {
    throw new Error('usePym can only be used inside PymProvider');
  }

  return child;
}