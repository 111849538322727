/** @jsxImportSource @emotion/react */
// Styles
import { stylesheet } from './style';
// Helpers
import * as models from 'models/index';
// State
import { useGlobalStyles } from 'store/cms';
// Components
import Markdown from 'components/markdown';

export interface IErrorMessageData {
  data: {
    copy: models.global.IGenericObject;
    link?: models.global.IGenericObject;
  };
  styles: models.global.IGenericObject;
  track?: string;
}

function ErrorMessage(props: IErrorMessageData) {

  const { copy, link } = props.data;

  // Styles
  const globalStyles = useGlobalStyles();

  const styles = stylesheet({
    errorStyles: props.styles,
    globalStyles: globalStyles
  });

  return (
    <div css={ styles.error } aria-live='assertive'>
      { copy.headline &&
        <h1 css={ styles.headline }> <Markdown copy={copy.headline} /> </h1> }
      
      { copy.description &&
        <p css={ styles.description }> <Markdown copy={copy.description} /> </p> }

      { link && link.url &&
        <a href={link.url} css={ styles.link } rel="noreferrer" target='_blank'> <Markdown copy={link.copy} /> </a> }
    </div>
  );
}

export default ErrorMessage;
