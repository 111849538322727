import ReactMarkdown from 'react-markdown';
import rehypeBrToNewline from 'util/rehype-br-to-newline';

const Markdown = ({ copy } : { copy: string | undefined }) => {
  const blockLevelElements = ['aside','blockquote','div','dl','figure','form','h1','h2','h3','h4','h5','h6','li','nav','ul','ol','p','section','table'];

  if (!copy) return null;
  
  return (
    <ReactMarkdown 
      children={copy}
      rehypePlugins={[rehypeBrToNewline]}
      disallowedElements={blockLevelElements}
      unwrapDisallowed={true}
      components={{ a: LinkRenderer }}
    />
  )
}

export default Markdown;

const LinkRenderer = (props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
  if (props.href?.match('http') || props.href?.match('https')) {
    return <a href={props.href} target="_blank" rel="nofollow noreferrer noopener"> { props.children } </a>
  }

  return <a href={props.href}> { props.children } </a>
}