import BASE64 from 'crypto-js/enc-base64';
import HMACSHA256 from 'crypto-js/hmac-sha256';
import * as models from 'models/index';
import * as constants from 'util/constants';
import { isMobile } from 'react-device-detect';
import { SortingDirection } from 'types';

export default class Util {
  /**
   * Deferred - create a deferred object to
   */
  public static Deferred(): models.promise.IDeferred {
    let res;
    let rej;

    const def = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    }) as models.promise.IDeferred;

    if (res && rej) {
      def.resolve = res;
      def.reject = rej;
    }

    return def;
  }
}

export function isValid (value: any) {
  return ['undefined', undefined, null,''].indexOf(value) < 0;
}

export const convertToQspString = (params: any) => {
  let result = '';
  for (const key in params) {
    const value = params[key];

    if (isValid(value)) {
      const encodedValue = fixedEncodeUriComponent(String(value));
      result += `${key}=${encodedValue}&`;
    }
  }
  return result.slice(0, -1);
};

export const createHash = (qsp: string, secret: string) => {
  const hash = HMACSHA256(qsp, secret);
  return BASE64.stringify(hash);
};

export const fixedEncodeUriComponent = (str: string) => {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, '%2A');
};

const toCamel = (s: any) => {
  return s.replace(/([-_][a-z])/ig, ($1: any) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

/**
 * normalizeStyles
 * This function modifies the 'o' object to normalize the stylings inside.
 * As usually that object is a reference to a React store data elements, modifying it introduces 
 * unexpected renders and issues. So if topLevel argument is not false, means we are calling it from
 * a component, so this will create a copy of the object received. Otherwise when it's false
 * means that the current execution comes from a recursive call
 * @param rawStyles 
 * @param topLevel 
 * @returns 
 */

export const normalizeStyles = function (rawStyles: any, topLevel?:boolean) {

  const o = topLevel === false? rawStyles: copyObject(rawStyles);

  if (isObject(o)) {
    const n: {[key: string]: any} = {};

    Object.keys(o)
      .forEach((k) => {
        if (k === 'background_image') {
          o[k] = o[k] && `url(${o[k]})`
        }

        if (k === 'hover' && !isMobile) {
          const key = ':hover';
          o[key] = o[k];
          k = key;
        }

        if (k === 'active') {
          const key = ':active';
          o[key] = o[k];
          k = key;
        }

        if(o[k] !== '' && o[k] !== null && o[k] !== undefined) {
          n[toCamel(k)] = normalizeStyles(o[k], false);
        }

      });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return normalizeStyles(i, false);
    });
  }

  return o;
};

export const convertRegExp = (string: string) => {
  if (!string || typeof string === 'object') return '';

  var regParts = string.match(/^\/(.*?)\/([gim]*)$/);
  if (regParts) {
      // the parsed pattern had delimiters and modifiers. handle them.
      return new RegExp(regParts[1], regParts[2]);
  } else {
      // we got pattern string without delimiters
      return new RegExp(string);
  }
}

export const shuffleArr = (arr: any[]) => {
  let shuffledArr = [...arr];
  let currentIndex = shuffledArr.length;
  let temporaryValue;
  let randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = shuffledArr[currentIndex];
    shuffledArr[currentIndex] = shuffledArr[randomIndex];
    shuffledArr[randomIndex] = temporaryValue;
  }

  return shuffledArr;
};

export const sortArrByProp = (arr: any[], field: string, dir: string) => {
  return [...arr].sort(
    (a: models.global.IGenericObject, b: models.global.IGenericObject) => {
      const aProp = a[field].toLocaleLowerCase();
      const bProp = b[field].toLocaleLowerCase();
      
      if (dir === SortingDirection.ASCENDING) {
        if (aProp < bProp) {
          return -1;
        }
        if (aProp > bProp) {
          return 1;
        }
        return 0;
      } else if (dir === SortingDirection.DESCENDING) {
        if (aProp < bProp) {
          return 1;
        }
        if (aProp > bProp) {
          return -1;
        }
        return 0;
      }
      return 0;
    }
  );
};

export const twitterShare = (copy: string, link?: string) => {
  const linkOut = link ? `&url=${link}` : '';
  const url = `${constants.TWITTER_INTENT_URL}${encodeURIComponent(copy)}${linkOut}`;
  window.open(url, '_blank', 'height=420,width=550');
};

// Fonts 
type CustomFontsData = {
  global_fonts: {
    font_settings: { url: string }[]
  }
}

type AccountFontsData = { url: string }[];

export type WidgetFontsData = {
  [key: string]: AccountFontsData[] | CustomFontsData;
};

export const loadWidgetFonts = (list: WidgetFontsData | undefined) => {
  if (!list) return;

  const isArray = Array.isArray(list);
  if (!list || (isArray && !list.length)) return;

  const fontsArray = isArray ? list : Object.values(list);
  fontsArray.forEach((font) => {
    // If font has property of font_settings, loop through font_settings array
    if (font.font_settings) {
      return loadWidgetFonts(font.font_settings);
    }

    const isFontLoaded = document.querySelector(`link[href="${font.url}"]`);
    if (!font.url || isFontLoaded) return;

    const fontLink = document.createElement('link');
    document.head.appendChild(fontLink);
    fontLink.rel = 'stylesheet';
    fontLink.href = font.url;
  });
};

export const getQueryParamByName = (name: string, url?: string) => {
  const regexS = '[\\?&#]' + name + '=([^&#]*)';
  const regex = new RegExp(regexS);

  const results = regex.exec( url || window.location.search ) ||
    regex.exec( window.location.hash );

  if ( results === null ) { return ''; }
  return decodeURIComponent( results[ 1 ].replace( /\+/g, ' ' ) );
};

export const sanitizePrefixedData = (data: Array<any>, prefix: string) => {
    return data.map(item => {
        Object.keys(item).forEach(key => {
            if (key.startsWith(`${prefix}_`)) {
                const newKey = key.replace(`${prefix}_`, '');
                item[newKey] = item[key];
            }
        });
        return item;
    });
};

export function deleteCookie(name:string , domain: string) {
  document.cookie = name + `=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const copyObject = ( data: any ) => {
  return isObject( data ) || isArray( data ) ? JSON.parse( JSON.stringify( data ) ): data;
}

export const replaceWildcardsWithProperties = (str: string, object: models.global.IGenericObject) => {
  const wildcards = str.match(/{{(.*?)}}/gi);

  if (!wildcards) return str;

  wildcards.forEach((wildcard) => {
    const property = wildcard.replace('{{', '').replace('}}', '').toLocaleLowerCase();
    
    str = str.replace(wildcard, (object[property] || '').trim())
  });
  
  return str
}

export const isEmpty = (obj: models.global.IGenericObject) => {
  return Object.keys(obj).length === 0;
}

export const trimValue = ( e: React.SyntheticEvent ) => {
  const target = e.target as HTMLTextAreaElement;
  target.value = target.value.trim();
};

export const getRulesetParameters = ( data: any ) => {
  const voteSettings = data?.snapshot?.voting?.vote_settings;
  return voteSettings?.ruleset_parameters ?? voteSettings?.settings;
}