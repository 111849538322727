// State
import { useWidget } from '@telescope/cassini-hooks';
// Helpers
import * as models from 'models/index';
import { useCallback } from 'react';
import { usePym } from './usePym';
import { Campaign } from 'types';

export const useScroll = () => {
  const pym = usePym();

  const { data: offsetSettings } = useWidget({
    select: (data: Campaign) => data.widget.settings.iframe.offset
  });

  const scrollToTop = useCallback(() => {
    if ( pym ) {
      let offset = 0;
  
      if ( offsetSettings ) {
        Object.values( offsetSettings as models.global.IGenericObject).forEach(( item ) => {
            const breakpoint = parseInt( item.breakpoint, 10 );
            if ( window.matchMedia( `(min-width: ${breakpoint}px)` ).matches ) {
              offset = parseInt( item.offset, 10 );
            }
        } )
      }
  
      const pos = JSON.stringify( { x: 0, y: -offset } );
      pym.sendMessage( 'scrollToChildPosition', pos );
    } else {
      window.scrollTo( 0, 0 );
    }
  }, [ pym, offsetSettings ]);

  return scrollToTop;
}