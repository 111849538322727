/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from 'react';
// Styles
import { normalizeStyles } from 'util/helpers';
import { stylesheet } from './style';
// State
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setLoginError } from 'store/user';
import { useGlobalStyles } from 'store/cms';
import { Campaign } from 'types';
// Components
import Markdown from 'components/markdown';
import { useWidget } from '@telescope/cassini-hooks';

function FbError() {
  const { data: errorsData } = useWidget({
    select: (data: Campaign) => data.widget.global_views.errors
  });
  const { content, styles } = errorsData!

  const { hasError } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  const globalStyles = useGlobalStyles();
  const errorsStyles = normalizeStyles(styles);

  const { copy, buttons } = content[ `fb_${hasError}` ];

  const clearFbError = useCallback(() => {
    dispatch(setLoginError(null));
  }, [ dispatch ]);

  useEffect(() => {
    return () => clearFbError();
  }, [ clearFbError ]);

  const style = stylesheet({
    fbErrorStyles: errorsStyles.fbPermissions,
    buttonStyles: errorsStyles.fbPermissions.buttons.error,
    globalStyles: globalStyles
  });

  return (
    <div css={ style.fbPermissions } aria-live='assertive'>

      { copy.headline && 
        <h1 css={ style.headline }> <Markdown copy={copy.headline} /> </h1> }

      { copy.description &&
      <p css={ style.description }> <Markdown copy={copy.description} />  </p> }

      <button
        aria-label='Try again'
        css={ style.facebook_button }
        onClick={() => clearFbError()}> 
        {buttons.error.copy}
      </button>
    </div>
  );
}

export default FbError;
