/** @jsxImportSource @emotion/react */
import { useEffect, useState, useCallback } from 'react';
// Styles
import { stylesheet } from './style';
// Helpers
import { getRulesetParameters, normalizeStyles } from 'util/helpers';
import { navigateTo, normalizeForUrl } from 'util/route-helpers';
import * as models from 'models/index';
import useEventListener from '@telescope/react-hooks/useEventListener';
import { isTablet, isMobile } from "react-device-detect";
// Constants
import * as constants from 'util/constants';
// Components
import Button from 'components/button';
import Video from 'components/video';
import { RightArrowIcon, LeftArrowIcon, PlusIcon, MinusIcon } from 'util/icons';
import Markdown from 'components/markdown';
// State
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useAppSettings, useContestants, useGlobalStyles, useProperties } from 'store/cms';
import { setVoteHistory, useVoteByReference, useCategory, useContestant } from 'store/vote';
import { openModal, MODAL_TYPES } from 'store/modal';
// Context
import { useVote, VoteAPIResponseCode } from '@telescope/cassini-hooks';
import { useWidget } from '@telescope/cassini-hooks';
//
import { Open } from 'types';

const BUTTON_TYPES = {
  SUBMIT: 'submit',
  PLUS: 'plus',
  MINUS: 'minus'
}

function Vote( props: models.global.IGenericObject ) {
  const { data: confirmation } = useWidget({
    select: (data: Open) => data.snapshot.snapshot_views.vote_confirmation
  });
  const { content, styles } = confirmation!;

  const getContestantVotes = useVoteByReference();

  const dispatch = useAppDispatch();

  const { user, ...auth } = useAppSelector(state => state.user);
  const voteHistory = useAppSelector(state => state.vote.history);
  const category = useCategory();
  const contestant = useContestant();

  const contestants = useContestants();

  const globalStyles = useGlobalStyles();
  const confirmationStyles = normalizeStyles(styles);

  const { data: voteSettings } = useWidget({
    select: (data: Open) => getRulesetParameters( data )
  })

  const { isMultiVote, isCategoryVote, isWindowOpen } = useAppSettings();
  const properties = useProperties();

  const MAX_CONTESTANT_VOTES = parseInt( voteSettings.contestant_vote_limit, 10 );
  const MAX_TOTAL_VOTES = parseInt( voteSettings.category_vote_limit, 10 );

  const defaultVoteCount = isMultiVote ? 0 : 1;

  const contestantVotes = getContestantVotes(contestant[constants.VOTE_CONSTANTS.SCHEMA_REFERENCE], category.category_key);
  const totalContestants = category.vote_choices.length;
  const [ voteCount, setVoteCount ] = useState( contestantVotes );

  const [ isSubmitting, setIsSubmitting ] = useState( false );

  // Api
  const vote = useVote();
  
  const style = stylesheet({
    globalStyles: globalStyles,
    voteStyles: confirmationStyles
  });

  // Set vote counts if the contestant changes
  useEffect( () => {
    const previouslySubmittedVoteCount = getContestantVotes(contestant[constants.VOTE_CONSTANTS.SCHEMA_REFERENCE], category.category_key);

    // If it's not a multivote, we automatically increment
    // the allocated votes by the default vote (usually 1)
    const voteCount = isMultiVote
      ? previouslySubmittedVoteCount
      : previouslySubmittedVoteCount + defaultVoteCount;

    setVoteCount( voteCount );
  }, [ contestant, isMultiVote, defaultVoteCount, category.category_key, getContestantVotes ] );

  const submitVote = async (voteCount: number) => {
    const contestantName = properties[contestant[constants.VOTE_CONSTANTS.SCHEMA_REFERENCE]];
    const device = isTablet? 'tablet' : isMobile? 'mobile' : 'desktop';

    try {
      const response = await vote({
        total: voteCount,
        category: category?.category_key,
        contestant: contestantName,
        action_type: constants.ACTION_TYPES.VOTE,
        device,
        ...user
      }, auth.authorization);

      if (response?.response_code === VoteAPIResponseCode.VALID) {
        handleVoteSuccess(response.votestring);
      } else {
        handleVoteError(response?.response_code as VoteAPIResponseCode || VoteAPIResponseCode.GENERAL_INVALID);
      }
    } catch (error) {
      console.error('Vote failed: ', error);
      handleVoteError(VoteAPIResponseCode.GENERAL_INVALID);
    }
  }

  const handleVoteSuccess = (votestring?: string) => {
    const history = votestring ? { ...JSON.parse(votestring) } : {};

    dispatch(setVoteHistory(history));
    dispatch(openModal({ type: MODAL_TYPES.confirmation }));
  }

  const handleVoteError = (error: VoteAPIResponseCode) => {
    let errorType = MODAL_TYPES.errorGeneric;

    switch (error) {
      case VoteAPIResponseCode.WINDOW:
        errorType = MODAL_TYPES.errorWindow;
        break;
      case VoteAPIResponseCode.OVERLIMIT:
        errorType = MODAL_TYPES.errorOverlimit;
        break;
      case VoteAPIResponseCode.GENERAL_INVALID:
      default:
        errorType = MODAL_TYPES.errorGeneric;
        break;
    }

    dispatch(openModal({ type: errorType }));
  }

  // Navigation
  const scrollTargetIndex = useCallback( ( direction: string ) => {
    
    let index = contestants.findIndex( ( c: any ) => contestant.id === c.id );

    index += ( direction === 'left' ? -1 : 1 );
    index = ( index + totalContestants ) % totalContestants;

    const nextContestant = contestants[ index ];

    const contestantName = normalizeForUrl( nextContestant.name );
    const categoryName = normalizeForUrl( category.category_title );
    const pathname = isCategoryVote ? `/${categoryName}/${contestantName}` : `/${contestantName}`;

    navigateTo(pathname);

  }, [ contestants, isCategoryVote, totalContestants, category.category_title, contestant.id ] );

  const handleKeyDown = useCallback(( e: any ) => {
    switch ( e.keyCode ) {
      case constants.KEYS.LEFT:
        scrollTargetIndex( 'left' );
        break;

      case constants.KEYS.RIGHT:
        scrollTargetIndex( 'right' );
        break;

      default:
        break;
    }
  }, [ scrollTargetIndex ]);

  useEventListener('keydown', handleKeyDown);

  const externalLinkLabel = contestant.link_accessibility?.replace( '{{NAME}}', contestant.name );

  return (
    <section css={ style.vote } aria-label='Confirm your vote' aria-roledescription='carousel'>
      <div css={ style.media_container }>
        { contestant.image && !contestant.video &&
          <img src={contestant.image} alt={contestant.name} /> }

        { contestant.video &&
          <div css={ style.video }>
            <div css={ style.video_wrapper }>
              <Video url={contestant.video} />
            </div>
          </div> }
      </div>

      <div css={ style.info_container }>

        { contestant.headline &&
          <p css={ style.headline }> <Markdown copy={contestant.headline} /> </p> }

        { !contestant.headline && content.universal_headline && isWindowOpen &&
          <p css={ style.headline }> <Markdown copy={content.universal_headline} /> </p> }

        <div aria-live="polite" aria-atomic="true" role="region">
          {contestant.name &&
            <h1 css={ style.name }>
              <Markdown copy={ contestant.name } />
            </h1>}
        </div>

        { contestant.description_1 &&
          <p css={ style.description_1 }>
            <Markdown copy={contestant.description_1} />
          </p> }

        { contestant.description_2 &&
          <p css={ style.description_2 }>
            <Markdown copy={contestant.description_2} />
          </p> }

        { contestant.description_3 &&
          <p css={ style.description_3 }>
            <Markdown copy={contestant.description_3} />
          </p> }
      </div>

      <div css={ style.cta_container }>

        { contestant.link_url && contestant.link &&
          <a href={contestant.link_url} target="_blank" rel="noreferrer" css={ style.link } aria-label={externalLinkLabel} className="VoteModal__external-link"> 
            { contestant.link }
          </a> }


        { isMultiVote && isWindowOpen && (
          <div css={ style.vote_buttons_wrapper }>
            <button
              css={[ style.vote_button, style.vote_button_minus ]}
              onClick={() => _handleVotesUpdate( -1 )}
              aria-disabled={isDisabled( BUTTON_TYPES.MINUS )}
              aria-label='subtract vote'>
              <MinusIcon />
            </button>

            <p css={ style.vote_counter }
              role='presentation'
              aria-hidden='true'>
              { voteCount }
            </p>

            <button
              css={[ style.vote_button, style.vote_button_plus ]}
              onClick={() => _handleVotesUpdate( 1 )}
              aria-disabled={isDisabled( BUTTON_TYPES.PLUS )}
              aria-label='add vote'>
              <PlusIcon />
            </button>
          </div> )}

        { isWindowOpen &&
          <>
            <Button
              aria-label='Submit Votes'
              copy={content.buttons.vote}
              buttonStyles={confirmationStyles.buttons.vote}
              options={{ globalStyles: globalStyles.buttons }}
              onClick={() => handleSubmit()}
              isDisabled={isDisabled( BUTTON_TYPES.SUBMIT )}
              data-vote-option-name={`${category.category_title}: ${contestant.name}`}
              className="VoteModal__vote-btn"
            />

            { isOverlimitVote() && content.overlimit_message &&
              <p css={ style.vote_counter }> <Markdown copy={content.overlimit_message} /> </p> }
          </> }

      </div>

      { props.children }

      { totalContestants > 1 && (
        <>
          <button
            aria-label='Previous contestant'
            css={[ style.nav_arrow, style.nav_prev ]}
            onClick={() => scrollTargetIndex( 'left' )}
          >
            <LeftArrowIcon />
          </button>

          <button
            aria-label='Next contestant'
            css={[ style.nav_arrow, style.nav_next ]}
            onClick={() => scrollTargetIndex( 'right' )}
          >
            <RightArrowIcon />
          </button>
        </> )}

    </section>
  );

  function handleSubmit() {
    // aria-disabled won't stop click events from being fired, so need to check here
    if ( isDisabled( BUTTON_TYPES.SUBMIT ) ) {
      return false;
    }

    if ( !auth.isAuthorized ) {
      dispatch(openModal({ type: MODAL_TYPES.auth, redirectTo: MODAL_TYPES.vote }));
    } else {
      submitVote(voteCount);
      setIsSubmitting( true );
    }
  }

  function _handleVotesUpdate( value: number ) {

    const newVotes = voteCount + value;
    const isValidVote = _isValidVote( newVotes );
    
    const allowIncrement = voteSettings.allow_plus;
    const allowDecrement = voteSettings.allow_minus;
    const disableIncrementButton =
      value > 0 && !allowIncrement && newVotes > contestantVotes;
    const disableDecrementButton =
      value < 0 && !allowDecrement && newVotes < contestantVotes;

    if ( disableIncrementButton || disableDecrementButton ) {
      return;
    }

    if ( !auth.isAuthorized ) {
      dispatch(openModal({ type: MODAL_TYPES.auth, redirectTo: MODAL_TYPES.vote }));
      return;
    }

    if ( !isValidVote ) {
      return;
    }

    setVoteCount( newVotes );
  }

  function isOverlimitVote(): boolean {
    const votesRemaining = getTotalVotesRemaining();
    // voteCount is allocated on component mount. All vote calculations use the allocated voteCount
    // Here votesRemaining can be equal to 0 because it's taking into account voteCount
    const hasTotalVotesRemaining = votesRemaining >= 0;
    const hasContestantVotesRemaining = !isMultiVote ?
      voteCount - defaultVoteCount < MAX_CONTESTANT_VOTES : true;

    return !hasTotalVotesRemaining || !hasContestantVotesRemaining;
  }

  function _isValidVote( count: number ): boolean {
    const totalVotesRemaining = getTotalVotesRemaining( count );

    if ( count > MAX_CONTESTANT_VOTES ) {
      return false;
    }
    if ( count < 0 ) {
      return false;
    }
    if ( totalVotesRemaining < 0 ) {
      return false;
    }

    return true;
  }

  function getTotalVotesRemaining( currentVotes = voteCount ) {
    const votesAlreadySubmitted = voteHistory.total || 0;

    const unsubmittedVotes = contestantVotes - currentVotes;

    return MAX_TOTAL_VOTES - votesAlreadySubmitted + unsubmittedVotes;
  }

  function isDisabled( button: string ) {
    const votesRemaining = getTotalVotesRemaining();
    const { allow_plus: allowIncrement, allow_minus: allowDecrement } = voteSettings;

    const disableIncrementButton =
      !allowIncrement && voteCount >= contestantVotes;
    const disableDecrementButton =
      !allowDecrement && voteCount <= contestantVotes;

    switch ( button ) {
      case BUTTON_TYPES.SUBMIT:
        const hasChanged = isMultiVote ? voteCount !== contestantVotes : true;
        const isOverlimit = isOverlimitVote();

        return isSubmitting || !hasChanged || isOverlimit;

      case BUTTON_TYPES.PLUS:
        return (
          isSubmitting || voteCount === MAX_CONTESTANT_VOTES ||
          votesRemaining <= 0 ||
          disableIncrementButton
        );
      case BUTTON_TYPES.MINUS:
        return isSubmitting || voteCount === 0 || disableDecrementButton;
      default:
        return false;
    }
  }

}

export default Vote;
