import { media, darkenColor } from 'util/style-variables';
import { Stylesheet } from 'models/styles';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    vote: {
      backgroundColor: '#fff',
      margin: 'auto',
      maxWidth: '850px',
      width: '100%',
      ...styles.voteStyles.general,
      ...styles.voteStyles.general?.mobile,

      [media?.tablet]: {
        ...styles.voteStyles.general?.tablet
      },

      [media?.desktop]: {
        ...styles.voteStyles.general?.desktop
      }
    },

    media_container: {
      padding: '30px 30px 0',

      img: {
        width: '100%'
      },

      [media?.tablet]: {
        padding: '0'
      }
    },

    video_wrapper: {
      position: 'relative',
      width: '100%',
      height: '0',
      paddingBottom: '56.25%',

      iframe: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0'
      },
    },

    info_container: {
      padding: '20px 25px 10px'
    },

    cta_container: {
      padding: '10px 25px 40px',

      [media?.tablet]: {
        padding: '20px ​25px 60px',
      }
    },

    universalHeadline: {
      fontSize: '18px'
    },

    headline: {
      padding: 0,
      marginBottom: 20,
      ...styles.voteStyles.headline,
      ...styles.voteStyles.headline?.mobile,

      [media?.tablet]: {
        ...styles.voteStyles.headline?.tablet
      },

      [media?.desktop]: {
        ...styles.voteStyles.headline?.desktop
      }
    },

    name: {
      marginBottom: 10,
      ...styles.voteStyles.name,
      ...styles.voteStyles.name?.mobile,

      [media?.tablet]: {
        ...styles.voteStyles.name?.tablet
      },

      [media?.desktop]: {
        ...styles.voteStyles.name?.desktop
      }
    },

    description_1: {
      paddingBottom: '8px',
      ...styles.voteStyles.description_1,
      ...styles.voteStyles.description_1?.mobile,

      [media?.tablet]: {
        ...styles.voteStyles.description_1?.tablet
      },

      [media?.desktop]: {
        ...styles.voteStyles.description_1?.desktop
      }
    },

    description_2: {
      paddingBottom: '5px',
      ...styles.voteStyles.description_2,
      ...styles.voteStyles.description_2?.mobile,

      [media?.tablet]: {
        ...styles.voteStyles.description_2?.tablet
      },

      [media?.desktop]: {
        ...styles.voteStyles.description_2?.desktop
      }
    },

    description_3: {
      paddingBottom: '5px',
      ...styles.voteStyles.description_3,
      ...styles.voteStyles.description_3?.mobile,

      [media?.tablet]: {
        ...styles.voteStyles.description_3?.tablet
      },

      [media?.desktop]: {
        ...styles.voteStyles.description_3?.desktop
      }
    },

    vote_buttons_wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '0 auto 20px',
        padding: '10px 0 8px',
        position: 'relative',
        width: '190px',
      },

      vote_counter: {
        margin: 'auto',
        paddingBottom: '0',
        fontSize: 40,
        ...styles.voteStyles.voteCount,
        ...styles.voteStyles.voteCount?.mobile,

        [media?.tablet]: {
          ...styles.voteStyles.voteCount?.tablet,
        },

        [media?.desktop]: {
          ...styles.voteStyles.voteCount?.desktop,
        }
      },

      vote_button: {
        height: '44px',
        margin: '0',
        width: '44px',

        svg: {
          verticalAlign: 'middle',
        },
      },

    vote_button_plus: {
        boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
        borderStyle: 'solid',
        ...styles.voteStyles.buttons.increase,

        polygon: {
          fill: 'inherit',
        },

        '[aria-disabled="true"]': {
          pointerEvents: 'none'
        }
      },

      vote_button_minus: {
        boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
        borderStyle: 'solid',
        ...styles.voteStyles.buttons.decrease,

        polygon: {
          fill: 'inherit',
        },

        '[aria-disabled="true"]': {
          pointerEvents: 'none'
        }
      },

    link: {
      marginBottom: '15px',
      ...styles.voteStyles.link,
      ...styles.voteStyles.link?.mobile,

      [media?.tablet]: {
        ...styles.voteStyles.link?.tablet
      },

      [media?.desktop]: {
        ...styles.voteStyles.link?.desktop
      }
    },

    nav_arrow: {
      position: 'absolute',
      top: 110,
      backgroundColor: 'transparent',

      ':hover svg': {
        fill: styles.voteStyles.navArrow?.mobile? darkenColor(styles.voteStyles.navArrow?.mobile.fill) : ''
      },

      [media.tablet]: {
        top: '50%',
        transform: 'translateY(-50%)',

        ':hover svg': {
          fill: styles.voteStyles.navArrow?.tablet? darkenColor(styles.voteStyles.navArrow?.tablet.fill) : ''
        }
      },

      [media.desktop]: {
        ':hover svg': {
          fill: styles.voteStyles.navArrow?.desktop? darkenColor(styles.voteStyles.navArrow?.desktop.fill) : ''
        }
      },

      svg: {
        width: '20px',
        ...styles.voteStyles.navArrow,
        ...styles.voteStyles.navArrow?.mobile,

        [media.tablet]: {
          width: '28px',
          ...styles.voteStyles.navArrow?.tablet
        },

        [media.desktop]: {
          ...styles.voteStyles.navArrow?.desktop
        }
      },
    },

    nav_prev: {
      left: '5px',

      [media?.tablet]: {
        left: '-50px'
      }
    },

    nav_next: {
      right: '5px',

      [media?.tablet]: {
        right: '-50px'
      }
    }
  }
}
