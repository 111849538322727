import { createSlice } from '@reduxjs/toolkit';
// Models
import * as models from 'models';
// Helpers
import { isEmpty } from 'util/helpers';
import { ICategory, IContestant } from 'models/cms';
import { useAppSelector } from './hooks';
import { useProperties, useCategories } from './cms';
import { useCallback, useMemo } from 'react';


export type IVoteHistory = { total?: number } & models.global.IGenericObject;
interface VoteState {
  history: IVoteHistory;
  categoryId: string;
  contestantId: string;
  historyFetched: boolean;
}

export const voteHistorySlice = createSlice({
  name: 'voteHistorySlice',
  initialState: {
    history: {},
    categoryId: '',
    contestantId: '',
    historyFetched: false
  } as VoteState,
  reducers: {
    setVoteHistory: (state, action) => {
      state.history = action.payload;
      state.historyFetched = true;
    },
    resetVoteHistory: (state) => {
      state.history = {}
    },
    setCategoryId: (state, action) => {
      if( state.categoryId !== action.payload ) {
        state.categoryId = action.payload;
        state.historyFetched = false; // Reset the fetched flag whenever the catId changes
      }      
    },
    setContestantId: (state, action) => {
      state.contestantId = action.payload
    }
  }
});

// Helpers
// TODO: improve vote history reference logic
export const useVoteByReference = () => {
  const voteHistory = useAppSelector(state => state.vote.history);
  const properties = useProperties();

  return useCallback((reference: string, categoryKey: string): number => {
    if ( isEmpty(voteHistory) || !reference ) {
      return 0;
    }
  
    const name = properties[reference];
    const contestantKey = `${categoryKey}-${name}_`;
  
    return voteHistory[ contestantKey ] || 0;
  }, [ properties, voteHistory ]);
}

export const useCategory = (): ICategory => {
  const categoryId = useAppSelector(state => state.vote.categoryId);
  const categories = useCategories(); 
  return useMemo(() => categories.find((cat) => cat.category_key === categoryId) || categories[0], [ categories, categoryId ]);
}

export const useContestant = (): IContestant => {
  const contestantId = useAppSelector(state => state.vote.contestantId);
  const category = useCategory();
  return useMemo(() => category.vote_choices.find((c) => c.id === contestantId), [ category, contestantId ]) as IContestant;
}

export const { setVoteHistory, resetVoteHistory, setCategoryId, setContestantId } = voteHistorySlice.actions;

export default voteHistorySlice.reducer;