/** @jsxImportSource @emotion/react */
import React, { useState, useCallback, useEffect } from 'react';
// Hooks
import { useForm } from 'react-hook-form';
// Helpers
import { AuthSubmitFn, RenderInputFn } from '.';
import { AUTH_TYPE, AUTH_METHODS } from './constants';
import { InputFieldData, AuthError, AuthTypes, AuthMethods } from './types';
import reactStringReplace from 'react-string-replace';
// Styles
import { stylesheet } from './style';
import { normalizeStyles } from 'util/helpers';
// State
import { useGlobalStyles } from 'store/cms';
// Components
import { FacebookIcon } from 'util/icons';
import { LoadingSpinner } from 'components/spinner';
import Markdown from 'components/markdown';

export interface IRegisterProps {
  content: Record<string,any>
  styles: Record<string,any>
  authMethods: Record<string,any>
  method: null | string
  defaultValues: Record<string,any>
  hasAuthTypeError: boolean
  authMethodError: AuthError
  isLoading?: AuthMethods
  setDefaultValues: (values: Record<string,any>) => void
  updateAuthMethod: (method: string) => void
  updateAuthType: (method: AuthTypes) => void
  renderInput: RenderInputFn
  onSubmit: AuthSubmitFn
}

const Register = ({ content, styles, authMethods, method, isLoading, updateAuthMethod, updateAuthType, renderInput, onSubmit, defaultValues, setDefaultValues, hasAuthTypeError, authMethodError } : IRegisterProps) => {
  const [ page, setPage ] = useState(1);

  // Form
  const requiredInputs = content.inputs.some((input: any) => input.required );
  const requiredOptins = content.optins.some((input: any) => input.required );

  const { handleSubmit, getValues, control, formState: { errors }, reset, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: true
  });

  const hasMultipleMethods = Object.keys(authMethods).filter((k) => authMethods[k]).length > 1;

  // Set values for prechecked optins
  useEffect(() => {
    content.optins?.forEach((input: InputFieldData) => {
      if (input.prechecked) {
        setValue(input.field_type, input.prechecked);
      }
    });
  }, [ content.optins ]);

  useEffect(() => {
    reset(defaultValues);
  }, [ page ])

  useEffect(() => {
    if (method) {
      onContinueSubmit();
    }
  }, [ method ]);

  const onContinueClick = (selectedMethod: string) => {
    if (selectedMethod === method) {
      onContinueSubmit();
    } else {
      updateAuthMethod(selectedMethod)
    }
  } 

  // Submit
  const onContinueSubmit = useCallback(() => {
    handleSubmit((data: Record<string,any>) => {
      onSubmit(data, () => { 
        setPage(2);
      }, { actionType: AUTH_TYPE.LOGIN });
    })();
  }, [ method, handleSubmit ]);

  const onFormSubmit = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();

    handleSubmit((data: Record<string,any>) => {
      onSubmit(data);
    })();
  }, [ method, handleSubmit ]);

  //
  const toggleAuthType = () => {
    const values = getValues();
    setDefaultValues(values);
    updateAuthType(AUTH_TYPE.LOGIN);
  }

  // Styles
  const registerStyles = normalizeStyles(styles);
  const globalStyles = useGlobalStyles();
  const style = stylesheet({
      globalStyles: globalStyles,
      authStyles: registerStyles,
      buttonStyles: registerStyles.buttons,
      isLoading
  });

  // Page 1
  const renderPageOne = () => {
    return (
      <div css={[ style.methodsContainer, style.formSection ]}>

        { hasMultipleMethods && content.sign_in_options &&
            <h3 css={ style.optionsLabel }> { content.sign_in_options } </h3> }

        { authMethods[AUTH_METHODS.FACEBOOK] &&
          <>
            <button type="button"
                css={[ style.button, style.facebookButton ]}
                onClick={() => { onContinueClick(AUTH_METHODS.FACEBOOK) }}>
                <FacebookIcon />
                { content.buttons.facebook }
                { isLoading === AUTH_METHODS.FACEBOOK && <LoadingSpinner position='inline' size={20} /> }
            </button> 
            
            { authMethodError?.method === AUTH_METHODS.FACEBOOK &&
              <p css={ style.errorMessage }> { authMethodError.message } </p> }
          </> }

          { authMethods[AUTH_METHODS.OAUTH] &&
            <>
            <button type="button"
              css={[ style.button, style.oauthButton ]}
              onClick={() => { onContinueClick(AUTH_METHODS.OAUTH) }}>
              { content.buttons.oauth }
              { isLoading === AUTH_METHODS.OAUTH && <LoadingSpinner position='inline' size={20} /> }
            </button> 
            
            { authMethodError?.method === AUTH_METHODS.OAUTH &&
              <p css={ style.errorMessage }> { authMethodError.message } </p> }
          </> }

        { hasMultipleMethods && authMethods[AUTH_METHODS.EMAIL] && 
          <div css={ style.divider }>
              <span css={ style.divider_line } />
              <span css={ style.divider_text }> OR </span>
              <span css={ style.divider_line } />
          </div> }

        { authMethods[AUTH_METHODS.EMAIL] && 
          <div css={ style.formSection }>
            
            { renderInput({ data: content.email_input, hasError: !!errors[content.email_input.field_type], styles: registerStyles, control }) }
           
            <button type="button"
                css={[ style.button, style.email_button ]}
                onClick={() => { onContinueClick(AUTH_METHODS.EMAIL) }}>
                { content.continue_button }
                { isLoading === AUTH_METHODS.EMAIL && <LoadingSpinner position='inline' size={20} /> }
            </button>

            { authMethodError?.method === AUTH_METHODS.EMAIL &&
                <p css={ style.errorMessage }> { authMethodError.message } </p> }
          </div> }

          <button type="button" onClick={ toggleAuthType } css={ style.authTypeLink }> { content.login_link } </button>

          { hasAuthTypeError && 
            <p css={ style.errorMessage }> { reactStringReplace(content.error_message.message, '{{LINK}}', (_, i) => (
              <button onClick={ toggleAuthType } key={i}> { content.error_message.link_text } </button> ))} </p> }

      </div>
    )
  }

  // TODO: on page 2, email input should be disabled
  const renderPageTwo = () => { 

    return (
      <div css={ style.formSection }>

        { authMethods[AUTH_METHODS.EMAIL] && 
          renderInput({ data: content.email_input, hasError: !!errors[content.email_input.field_type], styles: registerStyles, control, isDisabled: defaultValues.email }) }
        
        { content.inputs.map(((el: any) => <React.Fragment key={el.field_type}> { 
            renderInput({ data: el, hasError: !!errors[el.field_type], styles: registerStyles, control }) } </React.Fragment>))}

        { content.optins.map(((el: any) => <React.Fragment key={el.field_type}> { 
          renderInput({ data: el, hasError: !!errors[el.field_type], styles: registerStyles, control })} </React.Fragment>))}
        
        { content.required_fields_label &&
          ((content.email_input.required && method === AUTH_METHODS.EMAIL) || requiredInputs || requiredOptins) &&
          <span css={ style.requiredLabel }> { content.required_fields_label } </span> }
          
        <button type="button"
            css={[ style.button, style.email_button ]}
            onClick={(e) => isLoading? null : onFormSubmit(e)}>
            { method === AUTH_METHODS.EMAIL ? content.buttons.email : content.buttons.facebook }
            { isLoading && <LoadingSpinner position='inline' size={20} /> }
        </button>
      </div>
    )
  }

  return (
    <div aria-label='Sign up' css={ style.authModal }>
        { content.headline && 
            <h2 css={ style.headline }> <Markdown copy={content.headline} /> </h2> }

            <form css={ style.loginForm } onSubmit={(e: React.SyntheticEvent) => e.preventDefault()}>
              { (page === 1) && renderPageOne() }
              { (page === 2) && renderPageTwo() }
            </form>
    </div>
  );
}

export default Register;