/** @jsxImportSource @emotion/react */
import { useRef } from 'react';
// Styles
import { stylesheet } from './style';
// Helpers
import { normalizeStyles } from 'util/helpers';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';
import * as models from 'models/index';
import { normalizeForUrl, navigateTo } from 'util/route-helpers';
import { Open, CategoryVote } from 'types';
// Component
import Button from 'components/button';
import Markdown from 'components/markdown';
// State
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useCategories, useAppSettings, useGlobalStyles } from 'store/cms';
import { useVoteByReference, useCategory, useContestant } from 'store/vote';
import { closeModal } from 'store/modal';
import { useWidget } from '@telescope/cassini-hooks';

type CategoryVoteSettings = CategoryVote['snapshot']['snapshot_views']['thank_you']['settings'];
type CategoryVoteContent = CategoryVote['snapshot']['snapshot_views']['thank_you']['content'];

function Confirmation(props: models.global.IGenericObject) {  
  const { data: thankYou } = useWidget({
    select: (data: Open) => data.snapshot.snapshot_views.thank_you
  });
  const { content, settings, styles } = thankYou!;

  const getContestantVotes = useVoteByReference();
  const dispatch = useAppDispatch();

  // States
  const voteHistory = useAppSelector(state => state.vote.history);
  const categories = useCategories();
  const category = useCategory();
  const contestant = useContestant();

  const { isMultiVote, isCategoryVote } = useAppSettings();

  const globalStyles = useGlobalStyles();
  const confirmationStyles = normalizeStyles(styles);

  const style = stylesheet({
    globalStyles: globalStyles,
    confirmationStyles
  });

  const { data: voteSettings } = useWidget({
    select: (data: Open) => helpers.getRulesetParameters( data )
  });

  const MAX_TOTAL_VOTES = parseInt( voteSettings.category_vote_limit, 10 );

  const votes = useRef(getContestantVotes(contestant[constants.VOTE_CONSTANTS.SCHEMA_REFERENCE], category.category_key));
  const voteCopy = `${votes.current} ${votes.current === 1 ? 'VOTE' : 'VOTES'}`;

  const handleNextCategory = () => {
    const length = categories.length;

    let index = categories.findIndex( ( c: any ) => category.category_key === c.category_key );
    index = ( index + 1 + length ) % length;

    const nextCategory = categories[ index ];
    const nextCategoryName = normalizeForUrl( nextCategory.category_title );

    dispatch(closeModal());

    navigateTo(`/${nextCategoryName}`);
  }

  const handleTwitterVote = () => {
    if (content.twitter_vote.tweet?.copy) {   
      let tweet = content.twitter_vote.tweet.copy
        .replace(/{{CATEGORY}}/gi, category?.category_title || '')
        .replace(/{{CATEGORY_HASHTAG}}/gi, category?.hashtag || '');
      tweet = helpers.replaceWildcardsWithProperties(tweet, contestant).trim();

      helpers.twitterShare( tweet )
    }
  }

  return (
    <section>

      { contestant.image &&
        <div css={ style.media_container }>
          <img src={contestant.image} alt='' />
        </div> }

      <div css={ style.information_container } aria-live='assertive'>

        { content.headline &&
          <h1 css={ style.headline }> <Markdown copy={content.headline} /> </h1> }

        { isMultiVote &&
          <p css={ style.votes }> {voteCopy} </p> }

        { content.description_1 &&
          <p css={ style.description_1 }> <Markdown copy={content.description_1} /> </p> }

        { settings.display_vote_again && (( voteHistory.total || 0 ) < MAX_TOTAL_VOTES ) &&
          <>
            { content.description_2 &&
            <p css={ style.description_2 }> <Markdown copy={content.description_2} /> </p> }

            <Button
            copy={content.buttons.vote_again}
            buttonStyles={confirmationStyles.buttons.voteAgain}
            onClick={() => dispatch(closeModal())}
            options={{ 'pushDown': true, globalStyles: globalStyles.buttons }} 
            className="ThankYou__vote-again-btn"/>
          </>
        }

        { settings.display_twitter_vote &&
          <div css={ style.twitter_options }>

            { content.twitter_vote.headline &&
              <h2 css={ style.twitter_headline }>
                <Markdown copy={content.twitter_vote.headline} />
              </h2> }
            
            { content.twitter_vote.description &&
              <p css={ style.twitter_description }>
                <Markdown copy={content.twitter_vote.description} />
              </p>  }

            <Button
              copy={content.buttons.twitter_vote}
              buttonStyles={confirmationStyles.buttons.twitterVote}
              onClick={handleTwitterVote}
              options={{ 'pushDown': true, globalStyles: globalStyles.buttons }}
              className="ThankYou__twitter-vote-btn" />
          </div>
        }

        { isCategoryVote && (settings as CategoryVoteSettings).display_next_category && 
          <Button
            copy={(content as CategoryVoteContent).buttons.next_category}
            buttonStyles={confirmationStyles.buttons.nextCategory}
            onClick={() => handleNextCategory()}
            options={{ 'pushDown': true, globalStyles: globalStyles.buttons }}
            className="ThankYou__next-category-btn" />
        }

        {( settings.social_sharing?.display_facebook ||
          settings.social_sharing?.display_twitter ) &&
          props.children}

      </div>
    </section>
  );
}

export default Confirmation;
