/** @jsxImportSource @emotion/react */
import { useEffect, useCallback } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
// Styles
import { stylesheet } from './style';
// Helpers / Constants
import { loadWidgetFonts } from 'util/helpers';
import { storageFactory } from 'util/storage-helpers';
import * as constants from 'util/constants';
import { getAppConfig } from '@telescope/cassini-utilities';
import TagManager from 'react-gtm-module';
// Hooks
import { LoginFlow, useGeo } from '@telescope/cassini-hooks';
import { useWidget } from '@telescope/cassini-hooks';
import { FacebookLoginProvider, VoteApiProvider } from '@telescope/cassini-hooks';
// Components
import AdUnit from 'components/ad-unit/index';
import Categories from 'components/categories/index';
import Closed from 'components/closed/index';
import Confirmation from 'components/thanks/index';
import Countdown from 'components/countdown/index';
import Dropdown from 'components/dropdown/index';
import ErrorMessage from 'components/error-message/index';
import Footer from 'components/footer/index';
import Grid from 'components/grid/index';
import Header from 'components/header/index';
import Loading from 'components/loading/index';
import Modal from 'components/modal/index';
import Navigation from 'components/navigation/index';
import GlobalStyles from 'components/global-styles/index';
import { Auth, AUTH_METHODS } from 'components/auth';
import Share from 'components/share/index';
import User from 'components/user/index';
import Vote from 'components/vote/index';
// State
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useAppSettings, useGlobalStyles, SNAPSHOT_TYPES } from 'store/cms';
import { setUser, resetUser } from 'store/user';
import { MODAL_TYPES, openModal } from 'store/modal';
import { resetVoteHistory } from 'store/vote';
import { useDailyVoteLimit } from 'hooks/useDailyVoteLimit';
const { appHash, isDevMode } = getAppConfig();

const localStore = storageFactory(localStorage, appHash);

function App() {

  const { apiKey, versionId, isCategoryVote } = useAppSettings();
  const { data, isLoading, isError } = useWidget();
  const authSettings = data.snapshot.snapshot_views.login_logout?.settings;

  useDailyVoteLimit();

  const modal = useAppSelector(state => state.modal);
  const dispatch = useAppDispatch();

  const isWindowOpen = ( data?.snapshot.type === SNAPSHOT_TYPES.OPEN );
  const googleAnalytics = data?.widget?.settings?.general?.googleAnalytics || {};

  // Router
  const location = useLocation();

  // Geo blocking
  const geo: Record<string, any> = data?.widget.settings.general.geo;
  const countries = Array.isArray(geo?.countries) ? geo?.countries : [];
  const { aprAllowed, inRegion, isLoading: isGeoLoading } = useGeo(countries, {
    listType: geo?.countries_list_type,
    allowAprUsers: geo?.allow_apr
  });
  const isGeoLoaded = !isGeoLoading;

  // Should be good. TODO:: Check this works ok after refactor
  const readLocalStore = useCallback( async () => {
    const storedUser = localStore.getItem(constants.AUTH_LOCALSTORAGE_LABEL);
    const resetAuthStorage = () => localStore.removeItem(constants.AUTH_LOCALSTORAGE_LABEL);

    if ( !storedUser ) {
      return;
    }

    const payload = JSON.parse( storedUser );
    let { user: { method } } = payload;

    // logout user if snapshot changes
    if (!isLoading && payload && payload?.sid !== data?.sid) {
      method === AUTH_METHODS.FACEBOOK && window.FB.logout();
      resetAuthStorage();
      dispatch(resetUser());
      dispatch(resetVoteHistory());
      return;
    }

    switch ( method ) {
      case AUTH_METHODS.EMAIL:
      case AUTH_METHODS.OAUTH:
      case AUTH_METHODS.FACEBOOK:
        dispatch(setUser(payload))
        break;
      default:
        // if no valid method is found, remove auth from localstore
        resetAuthStorage();
    }

    return payload;
  }, [ dispatch, data?.sid, isLoading ]);

  // Bootstrap app after data is fetched
  useEffect( () => {
    if (!data?.widget || !data?.snapshot) return;

    async function bootstrap() {
      if (isWindowOpen) {
        await readLocalStore();
      }
    }

    bootstrap();

  }, [ data?.widget, data?.snapshot, isWindowOpen, dispatch, readLocalStore ]);

  // Initialize Google Tag Manager
  useEffect(() => {
    // If using styles-preview, don't initialize GA
    if (!data?.snapshot?.type || isDevMode) return;

    TagManager.initialize({
      gtmId: googleAnalytics.gtmContainerId as string,
      dataLayer: {
        client_ga_id: googleAnalytics.gaClientMeasurementId,
        product_ga_id: googleAnalytics.gaProductMeasurementId,
        snapshot_type: data.snapshot.type,
      },
    });
  }, [
    data?.snapshot?.type,
    googleAnalytics.gaProductMeasurementId,
    googleAnalytics.gaClientMeasurementId,
    googleAnalytics.gtmContainerId,
  ]);

  const siteWideStyles = useGlobalStyles();

  useEffect( () => {
    if( data?.widget ) {
      const {
        fonts: accountFonts, campaignFonts,
      } = data.widget;

      loadWidgetFonts({ ...accountFonts, ...campaignFonts });
    }
  }, [data?.widget]);

  useEffect(() => {
    if (location.pathname !== '/auth' || !data) return;

    dispatch(openModal({ type: MODAL_TYPES.auth }));

  }, [location.pathname, data]);


  if ( !data || isLoading || isError ) {
    return <Loading />;
  }

  // Render app
  const { app: pageStyles, content, ...globalStyles } = siteWideStyles;
  const styles = stylesheet({
    pageStyles: pageStyles,
    globalStyles: globalStyles,
    content,
    modalOpen: modal.type !== ''
  });

  const globalViews = data.widget.global_views;
  const { errors, footer } = globalViews;

  const { snapshot_views: snapshotViews, ads, voting } = data.snapshot;
  const { closed, header, navigation, thank_you: thanks } = snapshotViews;

  const navigationSettings = isWindowOpen && isCategoryVote? navigation.settings : null;

  const modalMap: { [ key: string ]: any } = {
    confirmation: (
      <Confirmation>
        <Share displayFacebook={ !!thanks?.settings.social_sharing?.display_facebook }
          displayTwitter={ !!thanks?.settings.social_sharing?.display_twitter } />
      </Confirmation> ),
    errorGeneric: <ErrorMessage data={errors.content.generic} styles={errors.styles.generic} />,
    errorOverlimit: <ErrorMessage data={errors.content.overlimit} styles={errors.styles.overlimit} />,
    errorWindow: <ErrorMessage data={errors.content.window} styles={errors.styles.window} />,
    auth: <Auth sid={data.sid} />,
    vote: <Vote />
  };

  const renderGrid = () => {
    return <>
      { isCategoryVote && navigationSettings.display_dropdown &&
        <Dropdown />}

      { isCategoryVote && isWindowOpen &&
        <User />}

      { /** TODO: Check if display_grid attirbute is still needed */}
      <Grid>
        { ads.square?.settings?.display &&
          <AdUnit size={constants.AD_UNITS.SQUARE} />}

      </Grid>

      { isCategoryVote && navigationSettings.display_navigation &&
        <Navigation />}
    </>
  }

  const renderClosedView = () => {
    return <Closed>
      { closed?.settings.display_countdown &&
        <Countdown key='countdown' />}
    </Closed>
  }

  return (
    <FacebookLoginProvider
                persistLogin={false}
                appId={authSettings?.facebook_app_id}
                storagePrefix={appHash}
                isLoginEnabled={!!authSettings?.display_facebook && !!authSettings?.facebook_app_id}
                loginFlow={LoginFlow.MANUAL}>
      <VoteApiProvider settings={{ apiKey, versionId }}>
      <GlobalStyles />

      <div css={styles.page}>
      
        <div css={styles.app_container} aria-hidden={modal.type !== ""}>

          { ads.leaderboard?.settings?.display &&
            <AdUnit size={constants.AD_UNITS.LEADERBOARD} />}

          { ads.mobile_leaderboard?.settings?.display &&
            <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD} />}

          { header.settings.display && <Header /> }

          { isGeoLoaded && inRegion && aprAllowed ?
            <main css={styles.mainContent} role='main'>

              {isWindowOpen === false &&
                renderClosedView()}

              { !isCategoryVote && isWindowOpen &&
                <User />}

              <Switch>

                <Route exact={true} path='/' render={() => {

                  if ( !isWindowOpen ) { return null; }

                  if ( isCategoryVote ) {
                    return (
                      <Categories>
                        { isWindowOpen &&
                        <User /> }
                      </Categories>
                    );
                  } else {
                    return renderGrid();
                  }

                }} />

                <Route path='/:name/:detail?' render={() => {

                  if ( !isWindowOpen ) { return null; }

                  return renderGrid();
                }} />
              </Switch>

              { ads.bottom_leaderboard?.settings?.display &&
                <AdUnit size={constants.AD_UNITS.LEADERBOARD_BOTTOM} />}

              { ads.mobile_bottom_leaderboard?.settings?.display &&
                <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD_BOTTOM} />}
            </main>: 
                <>
                  { isGeoLoaded && !inRegion &&
                    <ErrorMessage data={errors.content.geo} styles={errors.styles.geo} track={constants.GA_PAGES.GEO} /> }
                  
                  { isGeoLoaded && !aprAllowed &&
                    <ErrorMessage data={errors.content.apr} styles={errors.styles.apr} track={constants.GA_PAGES.APR} /> }
                </>
            }

          { footer.settings.display &&
            <Footer />}
        </div>

        {modalMap[ modal.type ] && isWindowOpen && (
          <Modal>
            {modalMap[ modal.type ]}

            { ads.modal?.settings?.display &&
              ( modal.type === MODAL_TYPES.confirmation ||
                modal.type === MODAL_TYPES.vote ) &&
              <AdUnit size={constants.AD_UNITS.MODAL} />}
          </Modal> )}

      </div>
      </VoteApiProvider>
    </FacebookLoginProvider>
  )
}

export default withRouter(App);
