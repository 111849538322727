/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';

type SelectOptions = {
  value: string;
  label: string;
}

type SelectProps = { 
  name: string, 
  label?: string, 
  options: SelectOptions[],
  placeholder?: string, 
  hasError: boolean, 
  errorMessage?: string, 
  style: any, 
  value?: string
}

export const SelectInput = forwardRef<HTMLSelectElement, SelectProps>(({ name, label, options, placeholder, hasError, errorMessage, style, value, ...rest }, ref) => {

  return (
    <div css={ style.formGroup }>

      { label &&
      <label css={ style.label } htmlFor={name}> { label } </label> }

      <div css={ style.selectContainer }>
        <img src="https://content.votenow.tv/app/products/vote-product/icons/Select_Dropdown.jpg" 
             alt="" role="presentation" css={ style.dropdownIcon }/>
      
        <select
          id={name}
          css={[ style.input, style.select, hasError && style.hasError, !value && style.inactiveInput ]}
          defaultValue=''
          ref={ref}
          value={value}
          { ...rest }> 

          <option key='' value='' disabled> { placeholder || '' } </option>

          { options.map(( { value, label } ) => {
            return <option key={value} value={value}> { label } </option> })}

        </select>
      </div>

      { hasError && errorMessage && 
        <p css={ style.errorMessage }> { errorMessage } </p> }

    </div>
  )
})