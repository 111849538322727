/** @jsxImportSource @emotion/react */
import { stylesheet } from './style';
// Util
import { FacebookIcon, TwitterIcon } from 'util/icons';
import * as helpers from 'util/helpers';
import { getAppConfig } from '@telescope/cassini-utilities';
import { Open } from 'types';
// State
import { useCategory, useContestant } from 'store/vote';
// Components
import Markdown from 'components/markdown';
import { useWidget } from '@telescope/cassini-hooks';

const { widgetId = window.wid } = getAppConfig();

interface IShareProps {
  displayFacebook: boolean;
  displayTwitter: boolean;
}

function Share(props: IShareProps) {

  const { data: thankYou } = useWidget({
    select: (data: Open) => data.snapshot.snapshot_views.thank_you
  });
  const { content, settings, styles } = thankYou!;

  const category = useCategory();
  const contestant = useContestant();

  const shareContent = content.social_sharing;
  
  const { displayFacebook, displayTwitter } = props;

  // Styles
  const nomalizedStyles = helpers.normalizeStyles(styles);

  const style = stylesheet({
    shareStyles: nomalizedStyles.share
  });
  
  const twitterShare = () => {
    let tweet = (shareContent.twitter.copy || '')
        .replace(/{{CATEGORY}}/gi, category?.category_title || '')
        .replace(/{{CATEGORY_HASHTAG}}/gi, category?.hashtag || '');
        tweet = helpers.replaceWildcardsWithProperties(tweet, contestant).trim();

    helpers.twitterShare( tweet, shareContent.twitter.link );
  }

  const facebookShare = () => {
    const { copy, url = window.location.href } = shareContent.facebook;
    const { share_contestant: shareContestant } = settings.social_sharing;

    let shareUrl = url;
    let quote = '';

    if (shareContestant) {
      shareUrl = `${window.location.origin}/share?wid=${widgetId}&contestant=${contestant.id}&category=${category.category_key}`;
    } else {
      quote = copy ? `&quote=${encodeURIComponent( copy )}` : '';
    }

    const u = encodeURIComponent(shareUrl);

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${u}${quote}`,
      'popup',
      'width=600, height=400, scrollbars=no'
    );
  }

  return (
    <div css={ style.share }>
      {( displayFacebook || displayTwitter ) &&
        <h3 css={ style.cta }> <Markdown copy={shareContent.cta} /> </h3>}

      { displayTwitter &&
        <button
          css={[ style.social_button, style.social_twitter ]}
          onClick={twitterShare}
          aria-label={shareContent.twitter.icon_accessibility}
          className="ShareButton"
          data-share-method="twitter">
          <TwitterIcon />
        </button>
      }

      { displayFacebook &&
        <button
          css={[ style.social_button, style.social_facebook ]}
          onClick={facebookShare}
          aria-label={shareContent.facebook.icon_accessibility}
          className="ShareButton"
          data-share-method="facebook">
          <FacebookIcon />
        </button>
      }
    </div>
  );
}

export default Share;