import { Stylesheet } from 'models/styles';
import { media, appMaxWidth } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    page: {
      ...styles.globalStyles.font,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%'
    },

    app_container: {
      backgroundColor: '#fff',
      height: 'auto',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'flex-start',
      zIndex: 0,
      position: 'relative',
      pointerEvents: styles.modalOpen? 'none' : 'normal',
      ...styles.pageStyles
    },

    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: (window.top !== window.self) ? 0 : 1,
      margin: '0 auto',
      maxWidth: appMaxWidth,
      width: '100%',
      paddingBottom: 0,
      padding: '20px 10px',
      ...styles.content,

      [media.tablet]: {
        padding: '25px 20px',
      }
    }
  }
}
