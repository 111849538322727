/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';

type TextInputProps = { 
  name: string, 
  type?: string,
  label?: string, 
  placeholder?: string,
  hasError: boolean, 
  errorMessage?: string, 
  maxLength?: number, 
  isDisabled?: boolean,
  style: any, 
  value: string
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ name, type = 'text', label, placeholder, hasError, errorMessage, style, maxLength, isDisabled, value, ...rest }, ref) => {

  return (
    <div css={ style.formGroup }>

    { label && <label css={ style.label } htmlFor={name}> {label} </label> }

    <input type={type}
          id={name}
          css={[ style.input, hasError && style.hasError, isDisabled && style.disabled ]}
          placeholder={placeholder}
          maxLength={maxLength}
          ref={ref}
          aria-disabled={isDisabled}
          readOnly={isDisabled}
          defaultValue={value}
          {...rest} />
    
    { hasError && errorMessage && 
      <p css={ style.errorMessage }> { errorMessage } </p> }

    </div>
  )
})