import { Stylesheet } from 'models/styles';

export const stylesheet = (): Stylesheet => {
  return {
    loading: {
      padding: '30px',
      textAlign: 'center',
      margin: 'auto'
    },

    loading_image: {
      background: 'rgba(255,255,255,.7)',
      borderRadius: '100%',
      margin: 'auto'
    },

    loading_title: {
      fontSize: '20px'
    },

    loading_description: {
      color: '#808080',
      fontSize: '16px',
      paddingTop: '10px'
    }
  }
}
