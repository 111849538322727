import { Stylesheet } from 'models/styles';
import { appMaxWidth } from 'util/style-variables';


export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    header: {
      margin: '0 auto',
      padding: 0,
      maxWidth: styles.settings.displayBleed ? 'none' : appMaxWidth,
      position: 'relative',
      width: '100%',
      ...styles.headerStyles.general,

      img: {
        width: '100%'
      }
    },

    container: {
      margin: '0 auto',
      maxWidth: appMaxWidth
    }
  }
}
