/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';
// Components
import Markdown from 'components/markdown';

type CheckboxProps = { 
  name: string, 
  label?: string, 
  hasError: boolean, 
  errorMessage?: string, 
  isPrechecked: boolean,
  style: any, 
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ name, label, hasError, errorMessage, style, isPrechecked, ...rest }, ref) => {

  return (
    <div css={ style.formGroup }>

    <input type='checkbox' id={name} css={ style.checkboxInput } defaultChecked={isPrechecked} ref={ref} {...rest} />
      <label htmlFor={name} css={[ style.checkbox, hasError && style.hasError ]} />
      
      { label &&
        <label htmlFor={name} css={[ style.label, style.checkboxLabel ]}> <Markdown copy={label} /> </label>  }

      { hasError && errorMessage && 
        <p css={[ style.errorMessage, style.checkboxErrorMessage ]}> { errorMessage } </p> }

    </div>
  )
})