/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
// Styles
import { stylesheet } from './style';
// Helpers
import * as models from 'models/index';
import * as constants from 'util/constants';
import TagManager from 'react-gtm-module';
import { normalizeStyles } from 'util/helpers';
import { Closed as ClosedSnapshot } from 'types';
// Components
import Markdown from 'components/markdown';
// State
import { useGlobalStyles } from 'store/cms';
// Hooks
import { useWidget } from '@telescope/cassini-hooks';

function Closed(props: models.global.IGenericObject) {
  const { data: closedData } = useWidget({
    select: (data: ClosedSnapshot) => data.snapshot.snapshot_views.closed
  });
  const { content, styles } = closedData!;

  const globalStyles = useGlobalStyles();

  useEffect(() => {
    TagManager.dataLayer({ dataLayer: { event: 'page_view', page: constants.GA_PAGES.CLOSED } });
  }, []);

  const style = stylesheet( {
    closedStyles: normalizeStyles(styles),
    globalStyles: globalStyles
  } );

  return (
    <div css={ style.closed }>
      { content.headline &&
      <h1 css={ style.headline }> <Markdown copy={content.headline} /> </h1> }

      { content.description && 
        <p css={ style.description }> <Markdown copy={content.description} /> </p> }

      { props.children }

      {content.image &&
        <img src={content.image} alt={content.image_alt_text} />}
    </div>
  );

}

export default Closed;
