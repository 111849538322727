// Ad Units
export const AD_UNITS = {
  LEADERBOARD: 'leaderboard',
  LEADERBOARD_BOTTOM: 'bottom_leaderboard',
  MOBILE_LEADERBOARD_BOTTOM: 'mobile_bottom_leaderboard',
  MOBILE_LEADERBOARD: 'mobile_leaderboard',
  MOBILE_RECTANGLE: 'mobile_rectangle',
  RECTANGLE: 'rectangle',
  SQUARE: 'square',
  MODAL: 'modal'
};

// Auth
export const AUTH_METHODS = {
  EMAIL: 'email',
  FACEBOOK: 'fb'
};

// API
export const ACTION_TYPES = {
  GET: 'get',
  VOTE: 'vote',
  LOGIN: 'login',
  REGISTER: 'register'
}

// Auth
export const AUTH_LOCALSTORAGE_LABEL = `_Auth`;

//Column Maximum Values
export const COLUMNS = {
  DESKTOP: 5,
  TABLET: 4,
  MOBILE: 2
}

// Geo Include
export const GEO_INCLUSIVE = 'inclusive'

// Vote constants
export const VOTE_CONSTANTS = {
  SCHEMA_REFERENCE : 'schema_reference',
  VERSION_ID_PREFIX : 'ssp_'
}

// Countdown
export const SECOND = 1;
export const MINUTE = 60;
export const HOUR = 3600;
export const DAY = 86400;

// Endpoints
export const API_GEO = '/api/getregion.json';
export const FACEBOOK_SHARE_PATH = '/contestant';
export const TWITTER_INTENT_URL = 'https://twitter.com/intent/tweet?text=';

// Polling Rate
export const MIN_POLLING_RATE = 3000;
export const CMSIFY_PREVIEW_DATA_POLLING_RATE_IN_MS = 1000000;

// Google Analytics
export const GA_PAGES = {
  GEO: 'Out of Geo',
  APR: 'APR blocked',
  GRID: 'Category',
  CLOSED: 'Closed',
  CATEGORIES: 'Categories',
  CONTESTANT: 'Contestant'
};
export const GA_CATEGORIES = {
  AUTO_EVENT: "Automatic Event",
  BUTTON_CLICK: 'Button Clicked',
  LINK_CLICK: 'Link Clicked',
  USER_LANGUAGE: 'User Language',
  USER_NATION: 'User Nation'
};
export const GA_EVENTS = {
  LOGIN: {
    EMAIL: 'Email Login',
    FACEBOOK: 'Facebook Login'
  },
  REGISTER: {
    EMAIL: 'Email Sign Up',
    FACEBOOK: 'Facebook Sign Up'
  },
  FACEBOOK_SHARE: 'Facebook Share',
  LOGOUT: 'Logout',
  SELECT_CONTESTANT: 'Select Contestant',
  SUBMIT_VOTE: 'Submit Vote',
  TWITTER_SHARE: 'Twitter Share',
  TWITTER_VOTE: 'Twitter Vote',
  DROPDOWN_TOGGLE: 'Dropdown Toggle',
  NAVIGATION: 'Navigation'
};

// Images
export const IMG_LOADING = '/images/loading.svg';

// Keyboard
export const KEYS = {
  DOWN: 40,
  ESCAPE: 27,
  LEFT: 37,
  RIGHT: 39,
  TAB: 9,
  UP: 38
};

// Response Codes
export const RESPONSE_CODES = {
  GENERAL_INVALID: '16',
  VALID: '20',
  OVERLIMIT: '21',
  OUT_OF_WINDOW: '40'
};

// Routing
export const URL_REGEX = /[^0-9A-Za-z$\-_.+!*'(),]/g;

// Navigation
export const NAVIGATE = {
  NEXT: 'next',
  PREV: 'prev',
  ALL: 'all'
}
// Sorting
export const SORT_BY = {
  ID: 'id',
  NAME: 'name',
  DESCRIPTION_1: 'description_1',
  DESCRIPTION_2: 'description_2',
  DESCRIPTION_3: 'description_3',
  HEADLINE: 'headline',
  IMAGE: 'image',
  VIDEO: 'video',
  LINK_URL: 'link_url',
  ORDER: 'order',
};