export interface IVideoProps {
  url: string;
}

function Video(props: IVideoProps) {
  return (
    <iframe title={props.url} width="560" height="315" src={props.url}
    frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen></iframe>
  )

}

export default Video;
