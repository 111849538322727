let initialPromise: any = null;

export const FB_ERROR_TYPE = {
  GENERIC: 'generic',
  PERMISSIONS: 'permissions'
}

export const FB_SCOPE = {
  EMAIL: 'email'
}

export const checkLoginState = (): Promise<any> => {
  if (!initialPromise) {
    throw new Error('fb_helpers.js initializeFbSdk must be called before any other helper functions.');
  }

  return new Promise((resolve: (value: any) => void, reject: any) => {
    initialPromise.then(() => {
      return window.FB && window.FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
          return getMyInfo()
            .then(response => resolve(response));
        }
        else { return reject('Unable to connect to Facebook'); }
      });
    });
  });
};

export const checkLogoutState = (): Promise<any> => {
  if (!initialPromise) {
    throw new Error('fb-helpers.js initializeFbSdk must be called before any other helper functions.');
  }


  return new Promise<any>((resolve: (value: any) => void, reject: any): void => {
    initialPromise.then(() => {
      window.FB && window.FB.getLoginStatus((response: any) => {
        if (response.status !== 'connected') {
          return resolve(response);
        }
        else { return reject('Unable to connect to Facebook'); }
      });
    });
  });
};

const getMyInfo = (): Promise<any> => {
  return new Promise((resolve: (value: any) => void) => {
    window.FB.api('/me', { fields: 'email,first_name,last_name' }, (response: any) => {
      resolve(response);
    });
  });
};

export const initializeFbSdk = (id: string) => {

  // return early if no id
  if (!id) {
    return;
  }

  if (initialPromise) {
    return;
  }
  initialPromise = new Promise((resolve) => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: id,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v3.2'
      });

      resolve(window.FB);
    };
  });

  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    (js as HTMLScriptElement).src = "https://connect.facebook.net/en_US/sdk.js";
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs);
    }
  }(document, 'script', 'facebook-jssdk'));

  return initialPromise;
};

/**
 * Serialize a plain object, simliar to jQuery.param()
 * @returns string of serialized data
 */
const serializePlainObject = (obj: any) => {
  let serialized = '';
  for (let key in obj) serialized += '&' + key + '=' + encodeURIComponent(obj[key]);
  return serialized.substring(1);
};
/**
 * Facebook share using a PHP proxy
 * @param data - plain object
 * @param url - the base url for FB share
 */
export const share = (url: string, data?: any) => {
  const shareURL = data ? `${url}?${serializePlainObject(data)}` : url;

  window.FB.ui({
    method: 'share',
    display: 'popup',
    href: shareURL
  });
};
