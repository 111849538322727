import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    error: {
      flexGrow: 1,
      padding: '25px 20px',
      textAlign: 'center'
    },

    headline: {
      fontSize: '24px',
      paddingBottom: '5px',
      ...styles.globalStyles.headlines,
      ...styles.errorStyles.headline,
      ...styles.globalStyles.headlines?.mobile,

      [media?.tablet]: {
        ...styles.globalStyles.headlines?.tablet
      },

      [media?.desktop]: {
        ...styles.globalStyles.headlines?.desktop
      }
    },

    description: {
      fontSize: '18px',
      ...styles.errorStyles.description
    },

    link: {
      ...styles.errorStyles.link
    }
  }
}
