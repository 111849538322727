/** @jsxImportSource @emotion/react */
import { useMediaQuery } from 'react-responsive';
// Styles
import { normalizeStyles } from 'util/helpers';
import { stylesheet } from './style';
import { breakpoints } from 'util/style-variables';
// State
import { useGlobalStyles } from 'store/cms';
import { useWidget } from '@telescope/cassini-hooks';
import { Campaign } from 'types';

function Header() {

  const { data:headerData  } = useWidget({
    select: (data: Campaign) => data.snapshot.snapshot_views.header
  });
  const { content, styles, settings } = headerData!;

  const isTablet = useMediaQuery({ minWidth: breakpoints.tablet });
  const isDesktop = useMediaQuery({ minWidth: breakpoints.desktop });

  // Styles
  const globalStyles = useGlobalStyles();

  const style = stylesheet({
    globalStyles: globalStyles,
    headerStyles: normalizeStyles(styles),
    settings: { displayBleed: settings.enable_background_bleed }
  });

  if (!content.background_image) return null;

  const { mobile, desktop, tablet } = content.background_image;

  const img = {
    ...mobile,
    ...(isTablet && tablet?.image && tablet),
    ...(isDesktop && desktop?.image && desktop)
  }

  return <header css={ style.header } role='banner'>

    <div css={ style.container }>
      <img src={img.image} alt={img.alt_text}/>
    </div>

  </header>;
}

export default Header;
