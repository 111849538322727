

/** @jsxImportSource @emotion/react */
// Styles
import { formStyleSheet } from './style';
//
import { useCallback, useState } from 'react';
// Hooks
import { FieldValues, Controller, Control } from 'react-hook-form';
import { useWidget, useVote, VoteAPIResponseCode } from '@telescope/cassini-hooks';
import { useSSOLogin } from '@telescope/react-hooks/useSSOLogin';
// Components
import Register from './register';
import Login from './login';
import FbError from 'components/fb-error/index';
import NumberFormat from 'react-number-format';
import { PhoneInput, DateInput, Checkbox, SelectInput, TextInput } from './components';
// Helpers
import { ACTION_TYPES, AUTH_LOCALSTORAGE_LABEL } from 'util/constants';
import { INPUTS_MAP, INPUT_TYPES, US_STATES, AUTH_TYPE, AUTH_METHODS } from './constants';
import { InputFieldData, AuthError, AuthTypes, AuthMethods } from './types';
import { getSSORedirectUri } from './utils';
import TagManager from 'react-gtm-module';
import * as fbHelpers from 'util/fb-helpers';
import { storageFactory } from 'util/storage-helpers';
import { getAppConfig } from '@telescope/cassini-utilities';
import history from 'util/history';
import { useFacebookLogin } from '@telescope/cassini-hooks';
// State
import { setVoteHistory, useCategory } from 'store/vote';
import { setUser, setLoginError } from 'store/user';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal, closeModal } from 'store/modal';
import { getRulesetParameters } from 'util/helpers';
import { Open } from 'types';


// Types
type AuthProps = {
  sid: number;
}

type AuthSuccessHandler = (payload: Record<string,any>) => void

export type AuthSubmitFn = (
  data: Record<string,any>,
  onSuccess?: AuthSuccessHandler,
  options?: { 
    actionType: string
  }
) => void

type RenderInputParams = {
  data: InputFieldData, control: Control<FieldValues>, 
  hasError?: boolean, styles: Record<string, any>, isDisabled?: boolean,
  setValue?: (name: string, value: any) => void
}
export type RenderInputFn = (props: RenderInputParams) => any

// Auth Facebook
export const FACEBOOK_AUTH_TYPES = {
  REAUTHENTICATE: 'reauthenticate',
  REAUTHORIZE: 'reauthorize',
  REREQUEST: 'rerequest'
}
// Login Types
export interface IDefaultLogin {
  user_id: string;
}

export interface IEmailLogin {
  email: string;
}
export interface IFacebookLogin {
  user_email: string;
  first_name?: string;
  last_name?: string;
}

export type IEmailLoginPayload = IDefaultLogin & IEmailLogin;
export type IFacebookLoginPayload = IDefaultLogin & IFacebookLogin;

const { appHash, basename } = getAppConfig();

// Auth Component
export const Auth = ({ sid }: AuthProps) => {

  const { data } = useWidget({
    select: (data: Open) => data.snapshot.snapshot_views.login_logout
  });
  const authData = data!

  const { data: settings } = useWidget({
    select: (data: Open) => getRulesetParameters( data )
  });
  const authSettings = settings!;

  const dispatch = useAppDispatch();
  const authenticate = useVote();

  const modal = useAppSelector(state => state.modal);

  const [ method, setMethod ] = useState<AuthMethods>(null);
  const [ authType, setAuthType ] = useState<AuthTypes>(AUTH_TYPE.LOGIN);
  const [ authTypeError, setAuthTypeError ] = useState<null | string>(null);
  const [ defaultValues, setDefaultValues ] = useState({});
  const [ authMethodError, setAuthMethodError ] = useState<AuthError>(null);
  const [ isLoading, setIsLoading ] = useState<AuthMethods>(null);
  const category = useCategory();

  const { login: fbLogin, logout: fbLogout } = useFacebookLogin(
    (data, state) => handleFacebookSuccess(data, state),
    () => console.log('facebook logout success'),
    (error) => handleFacebookError(error),
  )

  const ssoLogin = useSSOLogin(async (code, state, error) => {
    if (!authSettings.hasOIDC) return;

    const redirectUri = new URL(state.redirectUri);

    // Handle auth modal location
    history.replace({
      pathname: state.from || '/',
      // Only keep params from original redirect uri 
      search: redirectUri.searchParams.toString()
    });

    if (!code) {
      const ssoErrorMessages = authData.content.login_modal.oauth_error_messages;
      switch (error) {
        case 'access_denied':
          setAuthMethodError({ method: AUTH_METHODS.OAUTH, message: ssoErrorMessages?.access_denied || '' });
          return;
        default:
          setAuthMethodError({ method: AUTH_METHODS.OAUTH, message: ssoErrorMessages?.generic || '' });
          return;
      }
    }

    setIsLoading(AUTH_METHODS.OAUTH);

    try {
      let res = await authenticate({ 
        code, 
        action_type: ACTION_TYPES.LOGIN, 
        method: AUTH_METHODS.OAUTH,
        redirect_uri: state.redirectUri
      });
  
      setIsLoading(null);
  
      if (!res || (res.response_code === VoteAPIResponseCode.GENERAL_INVALID && authSettings.hasRegistration)) {
        // TODO: handle registration flow
        // return setAuthType(AUTH_TYPE.REGISTER);
        console.error('SSO login failed. Unkown error.');
        return null;
      } else if (res.response_code !== VoteAPIResponseCode.VALID) {
        console.error('SSO login failed. Invalid code.');
        return null;
      }
  
      const userInfo = JSON.parse(res.user_info);
      const payload = { 
        user: { ...userInfo, method: AUTH_METHODS.OAUTH },
        authorization: { token: res.token }
      }
      onAuthSuccess(payload);
      
    } catch(error) {
      console.error('SSO login failed.', error);
      setIsLoading(null);
    }

  }, {
    ssoUrl: authSettings.oauth_url,
    providerId: authSettings.oauth_provider_id,
    isSSOEnabled: authSettings.hasOIDC
  });

  const updateAuthMethod = useCallback((method: string) => {
    setMethod(method);
  }, [ method ]);

  const updateAuthType = (mode: AuthTypes) => {
    setMethod(null);
    setAuthType(mode);
    setAuthTypeError(null);
  }

  const authMethods = { [AUTH_METHODS.FACEBOOK]: authData?.settings.display_facebook && authData?.settings.facebook_app_id, 
                        [AUTH_METHODS.EMAIL]: authData?.settings.display_email,
                        [AUTH_METHODS.OAUTH]: authSettings.hasOIDC };
  

  // Storage
  // TODO: use utility-helpers PrefixedStorage
  // https://bitbucket.org/telescopeinc/utility-helpers/src/master/src/PrefixedStorage.ts
  const localStore = storageFactory(localStorage, appHash);

  const storeUserData = useCallback(( payload: Record<string, any> ) => {
    const storePayload = JSON.stringify({ ...payload, sid: sid });
    localStore.setItem( AUTH_LOCALSTORAGE_LABEL, storePayload );
  }, [ localStore, sid ]);

  const handleFacebookSuccess = async (data: any, state: string = '') => {
    setIsLoading(AUTH_METHODS.FACEBOOK);

    if (!data.id) {
      fbLogout();
      setIsLoading(null);
      return setLoginError(fbHelpers.FB_ERROR_TYPE.GENERIC);
    }

    if (!data.email && authData?.settings.request_facebook_email) {
      fbLogout();
      setIsLoading(null);
      return dispatch(setLoginError(fbHelpers.FB_ERROR_TYPE.PERMISSIONS));
    }

    const resPayload = {
      user_email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      user_id: data.id
    } as IFacebookLoginPayload;

    const formData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v ));
    const payload = { ...resPayload, ...formData, method: AUTH_METHODS.FACEBOOK };

    await login(payload, onAuthSuccess, { actionType: AUTH_TYPE.LOGIN });

    const resState = JSON.parse(window.atob(state));
    history.replace(resState.from);
  }

  const handleFacebookError = (error: any) => {
    console.error('Could not login with Facebook', error)
    return setLoginError(fbHelpers.FB_ERROR_TYPE.GENERIC)
  }

  // onSubmit
  const onAuthSubmit: AuthSubmitFn = async (data, onSuccess = onAuthSuccess, options = { actionType: authType }) => {
    let payload: Record<string,any> = {};

    setIsLoading(method);
    // Reset error message on submit
    setAuthMethodError(null);

    const redirectUri = getSSORedirectUri();
    // Handle method payload
    switch ( method ) {
      case AUTH_METHODS.FACEBOOK:
        const state = window.btoa(JSON.stringify({ from: window.location.pathname }));
        return fbLogin(redirectUri.toString(), state, {
          ...(authData?.settings.request_facebook_email && { scope: fbHelpers.FB_SCOPE.EMAIL }),
        });
       
      case AUTH_METHODS.EMAIL:
        const { email, ...rest } = data;
        payload = { user_id: email, ...rest, method: AUTH_METHODS.EMAIL };
        break;
      case AUTH_METHODS.OAUTH:
        return ssoLogin(redirectUri, { 
          from: window.location.pathname.split(basename)[1], 
          redirectUri: redirectUri,
          providerId: authSettings.oauth_provider_id
        });

      default:
        return;
    }

    if (!payload) {
      setIsLoading(null);
      console.error('Authentication failed. No user payload');
      // TODO: handle auth error
      return;
    }

    await login(payload, onSuccess, options);
  }

  const login = async (payload: Record<string, any>, onSuccess = onAuthSuccess, options = { actionType: authType }) => {
    try {
      const res = await authenticate({ ...payload, action_type: options.actionType, category: category?.category_key });

      const isValidCode = res.response_code === VoteAPIResponseCode.VALID;

      setIsLoading(null);

      // If authType is REGISTER but actionType is LOGIN:
      // We're checking if user is already registered and should be able to continue.
      // In this case response code should not be valid. 
      if (( authType === AUTH_TYPE.REGISTER && options.actionType === ACTION_TYPES.LOGIN )) {
        if (isValidCode) {
          return setAuthTypeError(AUTH_TYPE.REGISTER);
        } else {
          setDefaultValues({ ...payload, email: payload.method === AUTH_METHODS.EMAIL? payload.user_id : payload.user_email });
          return onSuccess({ user: payload });
        }
      } 

      // If authType === actionType, response code should be valid
      if (isValidCode) {
        const history = res.votestring ? { ...JSON.parse(res.votestring) } : {};
        dispatch(setVoteHistory(history));
        return onSuccess({ user: payload });
      };

      if (authType === AUTH_TYPE.LOGIN && authSettings.hasRegistration) {
        setAuthTypeError(AUTH_TYPE.LOGIN);
      } 
      
      if (authType === AUTH_TYPE.REGISTER) {
        setAuthTypeError(AUTH_TYPE.REGISTER);
      }

    } catch (error) {
      setIsLoading(null);
      console.error('Authentication failed. ', error);
      // TODO: handle auth error
    }
  }

  const onAuthSuccess: AuthSuccessHandler = (payload) => {
    if (!payload.user.method) return;

    dispatch(setUser(payload));
    storeUserData(payload);
    // TODO: on auth success, redirect to previous page 
    if ( modal.redirectTo ) {
      dispatch(openModal({ type: modal.redirectTo }));
    } else {
      dispatch(closeModal());
    }

    TagManager.dataLayer({ dataLayer: { event: authType.toLocaleLowerCase(), method: payload.user.method.toLocaleLowerCase() } });
  }

  const renderInput = ({ data, control, hasError, isDisabled, styles } : RenderInputParams) => {

    const fieldPreset = INPUTS_MAP[data.field_type];

    if (!fieldPreset) return null;

    const field = { ...fieldPreset, ...data }

    const style = formStyleSheet(styles);

    const name = data.field_type;
    const isRequired = name === AUTH_METHODS.EMAIL ? method === AUTH_METHODS.EMAIL : field.required;

    switch (field.type) {
      case INPUT_TYPES.CHECKBOX:
        return (
          <Controller
              key={name}
              render={({ field: controlledField }) => 
                <Checkbox style={style} 
                  label={field.label}
                  hasError={!!hasError} 
                  errorMessage={field.error_message}
                  isPrechecked={!!field.prechecked}
                  {...controlledField} /> }
              name={name}
              control={control}
              rules={{
                required: isRequired
              }}
            /> )
        
        case INPUT_TYPES.SELECT:
          // Select type currently only supports US States options
          return (
            <Controller
                key={name}
                render={({ field: controlledField }) => 
                  <SelectInput style={style} 
                    label={field.label}
                    placeholder={field.placeholder} 
                    hasError={!!hasError} 
                    errorMessage={field.error_message}
                    options={US_STATES}
                    {...controlledField} /> }
                name={name}
                control={control}
                rules={{
                  required: isRequired
                }}
              /> )

          case INPUT_TYPES.PHONE:
            return (
              <Controller
                key={name}
                render={({ field: { onChange, ...rest }}) => 
                  <NumberFormat
                    format="(###) ###-####" mask="_"
                    label={field.label}
                    placeholder={field.placeholder} 
                    hasError={!!hasError} 
                    errorMessage={field.error_message}
                    style={style}
                    {...rest}
                    customInput={PhoneInput}
                    onValueChange={({ value }) => onChange(value)}
                  /> }
                name={name}
                control={control}
                rules={{
                  required: isRequired, 
                  pattern: field.validation
                }}
              /> )

          case INPUT_TYPES.DATE:
            return (
              <Controller
                key={name}
                render={({ field: controlledField }) => 
                  <DateInput style={style} 
                    label={field.label}
                    hasError={!!hasError} 
                    errorMessage={field.error_message}
                    {...controlledField} /> }
                name={name}
                control={control}
                rules={{
                  required: isRequired, 
                  pattern: field.validation
                }}
              /> )
          
          case INPUT_TYPES.TEXT:
          default:
            return (
              <Controller
                key={name}
                render={({ field: controlledField }) => 
                  <TextInput style={style} 
                    label={field.label}
                    placeholder={field.placeholder}
                    hasError={!!hasError} 
                    errorMessage={field.error_message}
                    isDisabled={isDisabled}
                    maxLength={field.maxLength}
                    {...controlledField} /> }
                name={name}
                control={control}
                rules={{
                  required: isRequired, 
                  pattern: field.validation
                }}
              /> )
    }
  }
  
  return (
    <>
    { authType === AUTH_TYPE.REGISTER ? 
      <Register content={authData?.content.registration_modal} styles={authData?.styles.login} 
                authMethods={authMethods} method={method} updateAuthMethod={updateAuthMethod} 
                updateAuthType={updateAuthType} renderInput={renderInput} onSubmit={onAuthSubmit}
                defaultValues={defaultValues} setDefaultValues={setDefaultValues} hasAuthTypeError={authTypeError === AUTH_TYPE.REGISTER}
                isLoading={isLoading} authMethodError={authMethodError} /> :

      <Login SwapError={FbError} content={authData?.content.login_modal} styles={authData?.styles.login} 
             authMethods={authMethods} method={method} updateAuthMethod={updateAuthMethod} 
             updateAuthType={updateAuthType} renderInput={renderInput} onSubmit={onAuthSubmit}
             defaultValues={defaultValues} setDefaultValues={setDefaultValues}
             hasRegistration={authSettings.hasRegistration} hasAuthTypeError={authTypeError === AUTH_TYPE.LOGIN}
             isLoading={isLoading} authMethodError={authMethodError} /> }
    </>
  )
}

