import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    authModal: {
      padding: '25px 45px 35px',

      '*': {
        maxWidth: '320px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%'
      },

      a: {
        width: 'auto'
      },

      [media?.tablet]: {
        padding: '55px 45px',
      }
    },

    loginForm: {
      flexDirection: 'column',
      pointerEvents: styles.isLoading? 'none' : 'auto',
      opacity: styles.isLoading? 0.8 : 1,
    },

    buttonsGroup: {
      width: '100%'
    },

    headline: {
      textAlign: 'center',
      paddingBottom: 20,
      ...styles.globalStyles.headlines,
      ...styles.authStyles.headline,
      ...styles.globalStyles.headlines?.mobile,
      ...styles.authStyles.headline?.mobile,

      [media?.tablet]: {
        ...styles.globalStyles.headlines?.tablet,
        ...styles.authStyles.headline?.tablet
      },

      [media?.desktop]: {
        ...styles.globalStyles.headlines?.desktop,
        ...styles.authStyles.headline?.desktop
      }
    },

    requiredLabel: {
      ...styles.authStyles.requiredFieldsLabel,
      ...styles.authStyles.requiredFieldsLabel?.mobile,

      [media.tablet]: {
        ...styles.authStyles.requiredFieldsLabel?.tablet,
      },

      [media.desktop]: {
        ...styles.authStyles.requiredFieldsLabel?.desktop,
      }
    },

    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
      padding: '8px',
      width: '100%',
      minHeight: 50,
      minWidth: 120,

      span: {
        padding: 0
      },

      '.spinner': {
        transform: 'translate(12px, 3px)',
        margin: 0,
        width: 20
      }
    },

    email_button: {
      minWidth: '180px',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px',
      padding: '7px',
      borderStyle: 'solid',

      svg: {
        fill: styles.buttonStyles.email.color,
      },

      circle: {
        stroke: styles.buttonStyles.email.color,
      },

      ...styles.buttonStyles.email,
      ...styles.buttonStyles.email?.mobile,

      [media?.tablet]: {
        ...styles.buttonStyles.email?.tablet,
      },

      [media?.desktop]: {
        ...styles.buttonStyles.email?.desktop,
      }
    },

    facebookButton: {
      minWidth: '180px',
      lineHeight: '1',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px',
      fontSize: styles.buttonStyles.facebook.mobile.fontSize,
      borderStyle: 'solid',
      ...styles.buttonStyles.facebook,

      '.facebook-icon': {
        display: 'inline-block',
        verticalAlign: 'text-top',
        width: 20,
        margin: '0 15px 0 0'
      },

      svg: {
        fill: '#fff',
        height: 20,
        transform: 'translateY(1px)'
      },

      circle: {
        stroke: '#fff',
      },

      [media.tablet]: {
        fontSize: styles.buttonStyles.facebook.tablet.fontSize
      },

      [media.desktop]: {
        fontSize: styles.buttonStyles.facebook.desktop.fontSize
      },

      pointerEvents: styles.isLoading? 'none' : 'auto',
      opacity: styles.isLoading? 0.8 : 1
    },

    oauthButton: {
      ...styles.buttonStyles.oauth,

      [media.tablet]: {
        ...styles.buttonStyles.oauth?.tablet
      },

      [media.desktop]: {
        ...styles.buttonStyles?.oauth?.desktop
      },
    },

    optionsLabel: {
      ...styles.authStyles.optionsLabel,

      [media.tablet]: {
        ...styles.authStyles.optionsLabel?.tablet
      },

      [media.desktop]: {
        ...styles.authStyles.optionsLabel?.desktop
      }
    },

    divider: {
      display: 'flex',
      alignItems: 'center'
    },

    divider_line: {
      flexGrow: 1,
      height: '1px',
      backgroundColor: '#ccc',
      padding: 0,
      margin: 0,
      ...styles.authStyles.divider.line
    },

    divider_text: {
      padding: '0 15px',
      fontWeight: 'bold',
      fontSize: '16px',
      textAlign: 'center',
      ...styles.authStyles.divider.text
    },

    formGroup: {
      position: 'relative',
      textAlign: 'left',
    },

    // Areas
    formSection: {
      display: 'grid',
      gridGap: 15
    },

    optinsContainer: {
      gridArea: 'optins',
      marginBottom: 30
    },

    methodsContainer: {
      gridArea: 'methods'
    },

    globalInputsContainer: {
      gridArea: 'inputs'
    },

    errorMessage: {
      color: '#E34530',
      fontSize: '13px',
      marginTop: 5,
      textAlign: 'left',
      ...styles.authStyles.error,
      ...styles.authStyles.error?.mobile,

      '> button': {
        display: 'inline',
        padding: '0 3px',
        textDecoration: 'underline',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        width: 'auto',
        color: styles.authStyles.error.linkColor,
      },

      [media?.tablet]: {
        ...styles.authStyles.error?.tablet
      },

      [media?.desktop]: {
        ...styles.authStyles.error?.desktop
      }
    },

    authTypeLink: {
      marginLeft: 0,
      textDecoration: 'underline',
      width: 'auto',
      textAlign: 'left',
      ...styles.authStyles.loginLink,

      [media.tablet]: {
        ...styles.authStyles.loginLink?.tablet,
      },

      [media.desktop]: {
        ...styles.authStyles.loginLink?.desktop,
      }
    }
  }
}

export const formStyleSheet = (styles: Record<string,any>): Stylesheet => {
  return {
    formGroup: {
      position: 'relative',
      textAlign: 'left',
    },

    input: {
      backgroundColor: '#fff',
      border: '1px solid #000',
      fontSize: '14px',
      height: 45,
      padding: '0 10px',
      width: '100%',
      ...styles.input,
      ...styles.input?.mobile,

      [media?.tablet]: {
        ...styles.input?.tablet
      },

      [media?.desktop]: {
        ...styles.input?.desktop
      }
    },

    inactiveInput: {
      fontStyle: 'italic',
      color: '#ccc'
    },

    selectContainer: {
      position: 'relative'
    },

    select: {
      paddingRight: 54
    },

    dropdownIcon: {
      position: "absolute",
      fontSize: 10,
      right: 0,
      top: 0,
      height: 45,
      width: 45,
      pointerEvents: "none",
      cursor: "pointer"
    },

    disabled: {
      backgroundColor: '#D8D8D8',
      color: '#6b6b6b',
      pointerEvents: 'none'
    },

    label: {
      ...styles.label,
      ...styles.label?.mobile,

      [media?.tablet]: {
        ...styles.label?.tablet
      },

      [media?.desktop]: {
        ...styles.label?.desktop
      },

      a: {
        color: "#000000"
      }
    },

    checkbox: {
      background: 'linear-gradient(#D7D7D7, #FFF)',
      border: '1px solid #000',
      cursor: 'pointer',
      width: '16px',
      height: '16px',
      position: 'absolute',
      top: '1px',
      left: '0px',
      ...styles.checkbox.unchecked,
    },

    checkboxInput: {
      position: 'absolute',
      visibility: 'hidden',

      ':checked + label': {
        background: '#053555',
        ...styles.checkbox.checked,
      },

      ':checked + label:after': {
        content: "''",
        position: 'absolute',
        border: `1px solid ${styles.checkbox.checked.color}`,
        borderTop: 'none',
        borderRight: 'none',
        transform: 'rotate(-45deg)',
        top: '4px',
        left: '3px',
        width: '8px',
        height: '3px'
      }
    },

    checkboxLabel: {
      cursor: 'pointer',
      paddingLeft: '25px',
      minHeight: '16px',
      ...styles.checkbox.label,

      [media?.tablet]: {
        ...styles.checkbox.label?.tablet
      },

      [media?.desktop]: {
        ...styles.checkbox.label?.desktop
      },

      a: {
        color: styles.checkbox.linkColor
      }
    },

    hasError: {
      borderColor: '#C70000'
    },

    errorMessage: {
      color: '#940000',
      fontSize: '13px',
      marginTop: 5,
      textAlign: 'left',
      ...styles.error,
      ...styles.error?.mobile,

      '> button': {
        display: 'inline',
        padding: '0 3px',
        textDecoration: 'underline',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        color: styles.error.linkColor,
      },

      [media?.tablet]: {
        ...styles.error?.tablet
      },

      [media?.desktop]: {
        ...styles.error?.desktop
      }
    }
  }
}