/** @jsxImportSource @emotion/react */
import { stylesheet } from './style';
// Helpers
import * as models from 'models/index';
export interface IButtonData {
  ariaLabel?: string;
  copy: string;
  buttonStyles?: models.global.IGenericObject;
  onClick: () => any;
  options?: any;
  isDisabled?: boolean;
  className?: string;
}

function Button(props: IButtonData) {
  const styles = stylesheet({
    buttonStyles: props.buttonStyles || {},
    options: props.options || {}
  });

  return (
    <button css={ styles.button } onClick={props.onClick} aria-label={props.ariaLabel} aria-disabled={props.isDisabled} className={props.className}>
      { props.copy }
    </button>
  );
}

export default Button;
