export type Breakpoints = 'base' | 'md' | 'lg';

// Sorting 
export const SortingDirection = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  MANUAL: 'manual'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type SortingDirection = typeof SortingDirection[keyof typeof SortingDirection];