import { createSlice } from '@reduxjs/toolkit';
import { SortingDirection } from 'types';


type GridState = {
  sortingDirection: SortingDirection
}

export const gridSlice = createSlice({
  name: 'gridSlice',
  initialState: {
    sortingDirection: SortingDirection.MANUAL
  } as GridState,
  reducers: {
    setSortingDirection: (state, action) => {
      state.sortingDirection = action.payload
    }
  }
});

export const { setSortingDirection } = gridSlice.actions

export default gridSlice.reducer;