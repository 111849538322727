/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
// Styles
import { normalizeStyles } from 'util/helpers';
import { stylesheet } from './style';
// Helpers
import * as constants from 'util/constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { navigateTo, normalizeForUrl } from 'util/route-helpers';
import { Open } from 'types';
// Store / Models
import { IGenericObject } from 'models/global';
import { IContestant } from 'models/cms';
// Components
import Button from 'components/button';
import Markdown from 'components/markdown';
// State
import { useAppSelector } from 'store/hooks';
import { useAppSettings, useGlobalStyles } from 'store/cms';
import { useVoteByReference, useCategory } from 'store/vote';
import { useWidget } from '@telescope/cassini-hooks';

type PanelsProps = {
  contestants: IContestant[] 
};
type Props = IGenericObject & PanelsProps;

function Panels( props: Props ) {
  const { data: contestantCard } = useWidget({
    select: (data: Open) => data.snapshot.snapshot_views.contestant_card
  });
  const { content, settings, styles } = contestantCard!;

  const getContestantVotes = useVoteByReference();
  const isHistoryFetched = useAppSelector(state => state.vote.historyFetched);

  // States
  const { isAuthorized } = useAppSelector(state => state.user);
  const category = useCategory();
  
  // Content
  const buttonsData = content.buttons;

  // Settings
  const displayWinnerBanner = settings.display_winner_banner || category.display_winner;
  const { isCategoryVote, isWindowOpen } = useAppSettings();

  //Styles
  const globalStyles = useGlobalStyles();
  const contestantsStyles = normalizeStyles(styles);
  const buttonsStyles = contestantsStyles.buttons;

  const style = stylesheet({
    globalStyles: globalStyles,
    panelStyles: contestantsStyles
  });

  const renderVoteCount = useCallback(( contestant: IContestant ) => {
    if (!isHistoryFetched) return null;

    const reference = contestant[constants.VOTE_CONSTANTS.SCHEMA_REFERENCE];
    const count = getContestantVotes(reference, category.category_key);

    const copyKey = count === 1 ? 'singular' : 'plural';
    const countCopy = content.vote_count_label[ copyKey ];

    return (
      <p key={contestant.id}>
        <span css={ style.count }> {count}  </span>
        <span css={ style.countLabel }> <Markdown copy={countCopy} /> </span>
      </p>
    )
  }, [ isHistoryFetched, category.category_key, content.vote_count_label, getContestantVotes ])

  function handleClick( contestant: IContestant ) {
    const categorySlug = normalizeForUrl(category.category_title);
    const contestantSlug = normalizeForUrl(contestant.name);

    const path = isCategoryVote? `/${categorySlug}/${contestantSlug}` : `/${contestantSlug}`;
    navigateTo(path);
  };

  return (
    <>
      { props.contestants.map( ( contestant: IContestant, i: number ) => {

        const { vote } = buttonsData;
        const buttonData = vote;
        const buttonStyles = isWindowOpen ? buttonsStyles.vote : buttonsStyles.closedDisabled;

        return (
          <div css={ style.panel_wrapper } key={i}>

            <div css={ style.panel }>
              <LazyLoadImage
                alt=''
                src={contestant.image} />

              <div css={ style.text_wrapper }>
                <h2 css={ style.headline }
                  aria-label={ contestant.winner ?
                    content.winner?.replace( '{{NAME}}', contestant.name ) : ''}>
                  <Markdown copy={contestant.name } />
                </h2>

                { settings.display_description_1 && contestant.description_1 &&
                  <p css={ style.description_1 }> <Markdown copy={contestant.description_1} /> </p>}

                { settings.display_description_2 && contestant.description_2 &&
                  <p css={ style.description_2 }> <Markdown copy={contestant.description_2} /> </p>}

                { settings.display_description_3 && contestant.description_3 &&
                  <p css={ style.description_3 }> <Markdown copy={contestant.description_3} /> </p>}

                { isAuthorized && settings.display_vote_count && !displayWinnerBanner &&
                  renderVoteCount( contestant ) }

                {!displayWinnerBanner &&
                  <Button ariaLabel={`vote for ${contestant.name}`}
                    copy={buttonData}
                    buttonStyles={buttonStyles}
                    options={{
                      globalStyles: globalStyles.buttons,
                      disabled: !( isWindowOpen )
                    }}
                    onClick={() => handleClick( contestant )}
                    className="VoteOptionThumbnail__vote-btn"
                    data-vote-option-name={`${category.category_title}: ${contestant.name}`} />}
              </div>

              { contestant.winner && displayWinnerBanner &&
                <p css={ style.winner_banner }><Markdown copy={content.winner?.replace( '{{NAME}}', contestant.name )} /></p>}
            </div>
          </div>
        );
      })}
    </>
  )
}

export default Panels;
