import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';


export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    grid: {
      padding: '10px 0',
      width: '100%'
    },

    text_wrapper: {
      marginBottom: 20,

      [media?.tablet]: {
        marginBottom: 40,
      }
    },

    headline: {
      paddingBottom: '0px',
      lineHeight: 1.2,
      marginBottom: 3,
      ...styles.globalStyles.headlines,
      ...styles.gridStyles.headline,
      ...styles.globalStyles.headlines?.mobile,
      ...styles.gridStyles.headline?.mobile,

      [media?.tablet]: {
        ...styles.globalStyles.headlines?.tablet,
        ...styles.gridStyles.headline?.tablet
      },

      [media?.desktop]: {
        ...styles.globalStyles.headlines?.desktop,
        ...styles.gridStyles.headline?.desktop
      }
    },

    subheadline_wrapper: {
      display: 'flex',
      flexDirection: 'column',

      [media?.tablet]: {
        flexDirection: 'row'
      }
    },

    description: {
      flexGrow: 1,
      ...styles.gridStyles.description,
      ...styles.gridStyles.description?.mobile,

      [media?.tablet]: {
        marginBottom: 15,
        ...styles.gridStyles.description?.tablet
      },

      [media?.desktop]: {
        ...styles.gridStyles.description?.desktop
      }
    },

    cat_title: {
      ...styles.gridStyles.categoryTitle,
      ...styles.gridStyles.categoryTitle?.mobile,

      [media?.tablet]: {
        ...styles.gridStyles.categoryTitle?.tablet
      },

      [media?.desktop]: {
        ...styles.gridStyles.categoryTitle?.desktop
      }
    },

    cat_description: {
      ...styles.gridStyles.categoryDescription,
      ...styles.gridStyles.categoryDescription?.mobile,

      [media?.tablet]: {
        ...styles.gridStyles.categoryDescription?.tablet
      },

      [media?.desktop]: {
        ...styles.gridStyles.categoryDescription?.desktop
      }
    },

    info_container: {
      display: 'flex',
      alignContent: 'center'
    },

    additional_info: {
      fontSize: '14px',
      padding: 0,
      ...styles.gridStyles.additionalInfo,
      ...styles.gridStyles.additionalInfo?.mobile,

      [media?.tablet]: {
        fontSize: '16px',
        ...styles.gridStyles.additionalInfo?.tablet
      },

      [media?.desktop]: {
        ...styles.gridStyles.additionalInfo?.desktop
      }
    },

    info_separator: {
      display: 'inline-block',
      margin: '0 7px'
    },

    count: {
      ...styles.gridStyles.voteCountNumber,
      ...styles.gridStyles.voteCountNumber?.mobile,

      [media?.tablet]: {
        ...styles.gridStyles.voteCountNumber?.tablet
      },

      [media?.desktop]: {
        ...styles.gridStyles.voteCountNumber?.desktop
      }
    },

    countLabel: {
      padding: 0,
      ...styles.gridStyles.voteCountLabel,
      ...styles.gridStyles.voteCountLabel?.mobile,

      [media?.tablet]: {
        textAlign: 'center',
        ...styles.gridStyles.voteCountLabel?.tablet
      },

      [media?.desktop]: {
        ...styles.gridStyles.voteCountLabel?.desktop
      }
    },

    panel_window: {
      display: 'grid',
      width: '100%',
      gridGap: 20,
      marginBottom: 20,
      gridTemplateColumns: `repeat(${styles.columns.mobile}, 1fr)`,
      [media?.tablet]: {
        gridTemplateColumns: `repeat(${styles.columns?.tablet}, 1fr)` 
      },

      [media?.desktop]: {
        gridTemplateColumns: `repeat(${styles.columns?.desktop}, 1fr)` 
      }
    },

    sort_wrapper: {
      paddingTop: '10px',

      [media?.tablet]: {
        paddingTop: '0'
      }
    },

    sort_button: {
      color: '#fff',
      fontSize: '16px',
      ...styles.gridStyles.sortingOptions.general,
      ...styles.gridStyles.sortingOptions.general?.mobile,

      [media?.tablet]: {
        ...styles.gridStyles.sortingOptions.general?.tablet,
      },

      [media?.desktop]: {
        ...styles.gridStyles.sortingOptions.general?.desktop,
      }
    },

    sort_button_inactive: {
      backgroundColor: 'transparent',
      display: 'inline-block',
      cursor: 'pointer',
      color: 'inherit',
      fontSize: 'inherit'
    },

    sort_button_active: {
      backgroundColor: 'transparent',
      display: 'inline-block',
      pointerEvents: 'none',
      cursor: 'default',
      color: '#000',
      fontSize: 'inherit',
      ...styles.gridStyles.sortingOptions.selected,
      ...styles.gridStyles.sortingOptions.selected?.mobile,

      [media?.tablet]: {
        ...styles.gridStyles.sortingOptions.selected?.tablet,
      },

      [media?.desktop]: {
        ...styles.gridStyles.sortingOptions.selected?.desktop,
      }
    },

    video: {
      margin: '0 auto 40px',
      maxWidth: 700
    }
  }
}
