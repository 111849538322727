/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
// Styles
import { stylesheet } from './style';
// Helpers
import * as constants from 'util/constants';
import TagManager from 'react-gtm-module';
import { normalizeForUrl } from 'util/route-helpers';
import { IGenericObject } from 'models/global';
import { ICategory } from 'models/cms';
import history from 'util/history';
import { Link } from 'react-router-dom';
import { normalizeStyles } from 'util/helpers';
// Hooks
import { useScroll } from 'hooks/useScroll';
// State
import { useCategories, useGlobalStyles } from 'store/cms';
import { CategoryVote } from 'types';
// Components
import Markdown from 'components/markdown';
import { useWidget } from '@telescope/cassini-hooks';

function Categories(props: IGenericObject) { 
  const { data: categoriesData } = useWidget({
    select: (data: CategoryVote) => data.snapshot.snapshot_views.categories
  });
  const { content, styles } = categoriesData!;

  const categories = useCategories();

  const scrollToTop = useScroll();

  useEffect(() => {
    TagManager.dataLayer({ dataLayer: { event: 'page_view', page: constants.GA_PAGES.CATEGORIES } });
    scrollToTop();
  }, [ scrollToTop ]);

  // Styles
  const globalStyles = useGlobalStyles();

  const style = stylesheet({
    globalStyles,
    categoryStyles: normalizeStyles(styles)
  });

  return (
    <>
      { props.children }

      <section css={ style.categories }>
        { content.headline &&
          <h1 css={ style.headline }>
            <Markdown copy={content.headline} />
          </h1> }

        { content.description &&
          <p css={ style.description }>
            <Markdown copy={content.description} />
          </p> }

        <ul css={ style.categoriesContainer }>
          { categories.map(( category: ICategory, index: number ) => {
            const slug = normalizeForUrl( category.category_title );
            const isActive = category.active;
            const pathname = isActive ? slug : ''
            return (
              <li key={index} css={ style.category }>
                <Link to={{ pathname: pathname, search: history.location.search }} key={slug}
                  css={[ style.button, !isActive && style.disabled ]}
                  aria-disabled={isActive ? 'false' : 'true'} data-category-name={category.category_title}
                  className="CategoryThumbnail">
                  <Markdown copy={category.category_title} />
                </Link>
              </li>
            )
          })}
        </ul>
      </section>
    </>
  );
}

export default Categories;