/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';

type PhoneInputProps = { 
  name: string, 
  label?: string, 
  placeholder?: string, 
  hasError: boolean, 
  errorMessage?: string, 
  style: any, 
  value: string
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(({ name, label, placeholder, hasError, errorMessage, style, value, ...rest }, ref) => {

  return (
    <div css={ style.formGroup }>

    { label && <label css={ style.label } htmlFor={name}> {label} </label>  }

    <input type='tel'
          id={name}
          css={[ style.input, hasError && style.hasError ]}
          placeholder={placeholder}
          ref={ref}
          {...rest} />
    
    { hasError && errorMessage && 
      <p css={ style.errorMessage }> { errorMessage } </p> }

    </div>
  )
})