import { useMemo } from 'react';
// Models
import { CategoryVote, StandardVote, Open } from 'types';
// Helpers
import { sortArrByProp, normalizeStyles, getRulesetParameters } from 'util/helpers';
import { ICategory, IContestant } from 'models/cms';
import { useAppSelector } from './hooks';
import { SORT_BY } from 'util/constants';
import { SortingDirection } from 'types';
//
import { useWidget } from '@telescope/cassini-hooks';
import { useCategory } from './vote';

export const SNAPSHOT_TYPES = {
  OPEN: 'open',
  CLOSED: 'closed'
}

export const useAppSettings = () => {
  const { data } = useWidget();
  const widgetId =  data?.snapshot?.widget_id;
  const versionId = data?.snapshot?.version_id;
  const secretKey = data?.snapshot?.secret_key;

  const rulesetParams = getRulesetParameters( data );

  return {
    isCategoryVote: !!rulesetParams?.is_category_vote,
    isMultiVote: !!rulesetParams?.is_multi_vote,
    hasDailyVoteLimit: !!rulesetParams?.hasDailyVoteLimit,
    timezone: rulesetParams?.timezone,
    pollingRate: data?.widget.settings.api?.widget_state.cms_polling_rate * 1000,
    apiKey: data?.snapshot?.api_key,
    versionId: widgetId && versionId? `ssp_${widgetId}_${versionId}` : `ssp_${secretKey}`,
    isWindowOpen: data?.snapshot.type === SNAPSHOT_TYPES.OPEN
  }
}

export const useProperties = () => {
  const { data } = useWidget({
    select: (data: Open) => data.snapshot
  });
  const properties = data!.property_names as {
    name: string,
    reference: string
  }[];

  return useMemo(() => (properties || []).reduce((obj, property) => {
    obj[property.reference] = property.name;
    return obj;
  }, {}), [ properties ]);  
}

export const useCategories = (): ICategory[] => {
  const { data: voting } = useWidget({
    select: (data: Open) => data.snapshot.voting
  });

  const categories = useMemo(() => (
    (voting as CategoryVote['snapshot']['voting'])?.categories?.categories.filter((option: Record<string,any>) => !option.vote_category.hide 
  )), [(voting as CategoryVote['snapshot']['voting'])?.categories?.categories]);

  const defaultCategory = (voting as StandardVote['snapshot']['voting'])?.contestants?.default_category;

  return useMemo(() => {
    const categoriesArray = categories?  (categories || [])
                            .map( ( category: any ) => category.vote_category ) : [ defaultCategory ];

    return Object.values( categoriesArray || {} );
  }, [ voting, categories, defaultCategory ]);
}

export const useContestants = () => {  
  const { data } = useWidget();
  const { display_sorting_methods: displaySorting = false } = data?.snapshot?.snapshot_views.grid.settings;
  const category = useCategory();
  const voteChoices = category.vote_choices;
  const sortingDirection = useAppSelector(state => state.grid.sortingDirection);

  return useMemo(() => {
    const getSortedContestants = (contestants: IContestant[], sortingDirection: SortingDirection = SortingDirection.MANUAL) => {
      if (sortingDirection === SortingDirection.MANUAL) return contestants;
      const direction = sortingDirection.toLowerCase();
      return sortArrByProp(contestants, SORT_BY.NAME, direction);
    }

    const visibleContestants = voteChoices.filter((option: IContestant) => !option.hide);

    return displaySorting && visibleContestants? 
          getSortedContestants(visibleContestants, sortingDirection) : visibleContestants;
  }, [ voteChoices, sortingDirection, displaySorting ])
}

// TODO: globalStyles do not need to be normalized on every component
// Improve styles logic 
export const useGlobalStyles = () => {
  const { data } = useWidget({
    select: (data: Open) => data.widget.global_views.sitewide.styles
  });
  
  return useMemo(() => normalizeStyles(data), [data])
}