/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useCallback } from 'react';
// Styles
import { stylesheet } from './style';
import { normalizeStyles } from 'util/helpers';
// 
import * as models from 'models/index';
import * as constants from 'util/constants';
import { CloseIcon } from 'util/icons';
// Hooks
import { useScroll } from 'hooks/useScroll';
// State
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modal';
import { useWidget } from '@telescope/cassini-hooks';
import { Open } from 'types';

function Modal(props: models.global.IGenericObject) {

  const { data } = useWidget({
    select: (data: Open) => data.widget.global_views.modals
  });
  const { styles } = data!;

  const modalRef = useRef<HTMLDialogElement>(null);

  let focusableEls = useRef<any>([]);
  let firstFocusableEl: any = useRef(null);
  let lastFocusableEl: any = useRef(null);

  const dispatch = useAppDispatch();

  const modal = useAppSelector(state => state.modal);

  const scrollToTop = useScroll();

  // prevent scrolling if modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => { document.body.style.overflow = 'auto'; };
  }, [modal.type]);

  // Styles
  const style = stylesheet({
    modalStyles: normalizeStyles(styles)
  });
  
  const trapFocus = useCallback( () => {
    if ( !modalRef.current ) return;

    const focusable = modalRef.current.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    );
    focusableEls.current = Array.prototype.slice.call(focusable);

    firstFocusableEl.current = focusableEls.current[0];
    lastFocusableEl.current = focusableEls.current[focusableEls.current.length - 1];

    firstFocusableEl.current.focus();
  }, [] );

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [ dispatch ]);

  const handleKeyDown = useCallback( ( e: any ) => {
    switch ( e.keyCode ) {

      case constants.KEYS.ESCAPE:
        handleCloseModal();
        break;

      case constants.KEYS.TAB:
        if ( focusableEls.current.length === 1 ) {
          break;
        }

        if ( e.shiftKey ) {
          if ( document.activeElement === firstFocusableEl ) {
            e.preventDefault();
            lastFocusableEl.focus();
          }
        } else {
          if ( document.activeElement === lastFocusableEl ) {
            e.preventDefault();
            firstFocusableEl.focus();
          }
        }
        break;

      default:
        break;
    }
  }, [ handleCloseModal ] );

  useEffect(() => {
    requestAnimationFrame( () => trapFocus() );
    scrollToTop();

    document.addEventListener( 'keydown', handleKeyDown );
    document.body.classList.add( 'no-scroll' );

    return () => {
      document.body.classList.remove( 'no-scroll' );
      document.removeEventListener( 'keydown', handleKeyDown );
    }
  }, [ handleKeyDown, trapFocus, scrollToTop ]);

  useEffect(() => {
    trapFocus();
    scrollToTop();
  }, [ modal.type, trapFocus, scrollToTop ])

  return (
    <dialog
      css={ style.modal }
      onClick={handleCloseModal}
      ref={modalRef}>

      <div css={ style.container }>
        <div css={ style.content } onClick={( e ) => { e.stopPropagation() }}>
          <button
            aria-label='close modal'
            onClick={handleCloseModal}
            css={ style.close }>
            <CloseIcon />
          </button>

          { props.children }
        </div>
      </div>

    </dialog>
  );
}

export default Modal;
