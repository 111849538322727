/** @jsxImportSource @emotion/react */
import { stylesheet } from './style';
// util
import * as constants from 'util/constants';
import { LeftArrowIcon, RightArrowIcon } from 'util/icons';
import { normalizeStyles } from 'util/helpers';
import { normalizeForUrl, navigateTo } from 'util/route-helpers';
import { CategoryVote } from 'types';
// State
import { useCategories, useGlobalStyles } from 'store/cms';
import { useCategory } from 'store/vote';
import { useWidget } from '@telescope/cassini-hooks';

function Navigation() {
  const { data: navigation } = useWidget({
    select: (data: CategoryVote) => data.snapshot.snapshot_views.navigation
  });
  const { content, styles } = navigation!;

  const categories = useCategories();
  const category = useCategory();

  // Styles 
  const globalStyles = useGlobalStyles();

  const style = stylesheet({
    globalStyles: globalStyles,
    navigationStyles: normalizeStyles(styles.arrow_navigation)
  });

  // Navigation
  let index = categories.findIndex( ( c: any ) => category.category_key === c.category_key );
  let prevCategoryName = index > 0 ? categories[ index - 1 ].category_title : categories[ categories.length - 1 ].category_title;
  let nextCategoryName = index < categories.length - 1 ? categories[ index + 1 ].category_title : categories[ 0 ].category_title;

  const handleNavigation = ( navigate: string ) => {
    if ( navigate === constants.NAVIGATE.ALL ) {
      navigateTo('/');
      return;
    }

    const length = categories.length;
  
    let index = categories.findIndex( ( c: any ) => category.category_key === c.category_key );

    index += ( navigate === constants.NAVIGATE.PREV ? -1 : 1 );
    index = ( index + length ) % length;

    const nextCategory = categories[ index ];
    const categoryName = normalizeForUrl( nextCategory.category_title );

    navigateTo(`/${categoryName}`);
  };

  return (
    <nav css={ style.navigation } aria-label="Categories Navigation">
      <button css={ [style.button, style.prevButton] } 
              onClick={() => handleNavigation(constants.NAVIGATE.PREV)} 
              aria-label={ content.prev_category_accessibility }
              className="NavigationButton">
        <span> <LeftArrowIcon /><span> { prevCategoryName } </span></span>
      </button>

      { content.all_categories && 
        <button css={ style.button } onClick={() => handleNavigation(constants.NAVIGATE.ALL)} className="NavigationButton">
          {content.all_categories}
        </button> }

      <button css={[ style.button, style.nextButton ]} 
              onClick={() => handleNavigation(constants.NAVIGATE.NEXT)} 
              aria-label={ content.next_category_accessibility }
              className="NavigationButton">
        <span> <span> { nextCategoryName } </span> <RightArrowIcon />  </span>
      </button>
    </nav>
  );
}

export default Navigation;
