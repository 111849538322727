import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    closed: {
      textAlign: 'center',
      flexGrow: 1,
      padding: '100px 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      [media.tablet]: {
        padding: '0 20px'
      },

      a: {
        color: 'inherit',
        fontFamily: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit'
      }
    },

    headline: {
      lineHeight: 1.1,
      marginBottom: 10,
      ...styles.globalStyles.headlines,
      ...styles.closedStyles.headline,
      ...styles.globalStyles.headlines?.mobile,
      ...styles.closedStyles.headline?.mobile,

      [media?.tablet]: {
        fontSize: '45px',
        paddingBottom: '2px',
        ...styles.globalStyles.headlines?.tablet,
        ...styles.closedStyles.headline?.tablet
      },

      [media?.desktop]: {
        ...styles.globalStyles.headlines?.desktop,
        ...styles.closedStyles.headline?.desktop
      }
    },

    description: {
      paddingBottom: '26px',
      ...styles.closedStyles.description,
      ...styles.closedStyles.description?.mobile,

      [media?.tablet]: {
        fontSize: '20px',
        paddingBottom: '19px',
        ...styles.closedStyles.description?.tablet
      },

      [media?.desktop]: {
        paddingBottom: '44px',
        ...styles.closedStyles.description?.desktop
      }
    },

    video_wrapper: {
      position: 'relative',
      width: '100%',
      height: '0',
      paddingBottom: '56.25%',

      iframe: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0'
      },

      [media?.desktop]: {
        width: '640px',
        height: '360px',
        paddingBottom: '0',
        margin: 'auto'
      }
    },

    countdown_finished: {
      paddingBottom: '26px',
      ...styles.closedStyles.countdownClock.countdownFinishedLabel,
      ...styles.closedStyles.countdownClock.countdownFinishedLabel?.mobile,

      [media?.tablet]: {
        fontSize: '20px',
        paddingBottom: '19px',
        ...styles.closedStyles.countdownClock.countdownFinishedLabel?.tablet
      },

      [media?.desktop]: {
        paddingBottom: '44px',
        ...styles.closedStyles.countdownClock.countdownFinishedLabel?.desktop
      }
    }
  }
}
