import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    button: {
      backgroundColor: 'black',
      color: '#fff',
      fontSize: '12px',
      minWidth: '110px',
      minHeight: 50,
      marginBottom: styles.options.pushDown ? '10px' : '0',
      padding: '10px',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px',
      borderStyle: 'solid',
      ...styles.options.globalStyles,
      ...styles.buttonStyles,

      [media?.tablet]: {
        ...styles.buttonStyles?.tablet,
      },

      [media?.desktop]: {
        ...styles.buttonStyles?.desktop,
      }
    }
  }
}
