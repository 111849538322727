/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';
// Helpers
import 'configurable-date-input-polyfill';

type DateInputProps = { 
  name: string, 
  label?: string, 
  hasError: boolean, 
  errorMessage?: string, 
  style: any, 
  value?: string
}

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(({ name, label, hasError, errorMessage, style, value, ...rest }, ref) => {

  return (
    <div css={ style.formGroup }>

    { label && <label css={ style.label } htmlFor={name}> {label} </label>  }

    <input type='date'
      id={name}
      css={[ style.input, hasError && style.hasError, !value && style.inactiveInput ]}
      ref={ref}
      value={value}
      { ...rest } />
      
    { hasError && errorMessage && 
      <p css={ style.errorMessage }> { errorMessage } </p> }

    </div>
  )
})