export const breakpoints = {
  mobile: 320,
  phablet: 550,
  tablet: 768,
  desktop: 980
}

export const media = {
  mobile: '@media (min-width: 320px)',
  tablet: '@media (min-width: 750px)',
  desktop: '@media (min-width: 980px)',
  hover: '@media (hover: hover) and (pointer: fine)'
};

export const appMaxWidth = '980px';

export const darkenColor = (color: string) => {
  let hex = '';

  switch (color) {
    case undefined:
    case null:
    case '#000':
    case '#000000':
      break;
    case '#fff':
    case '#ffffff':
      hex = '#ccc';
      break;
    default:
      const percent = -0.2;
      const f = parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=(f>>8)&0x00FF,B=f&0x0000FF;
      hex = "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
      break;
  }

  return hex;
}