export const storageFactory = (storage: any, prefix: string = '') => {
  let inMemoryStorage: {[key: string]: any} = {};
  const length = 0;

  const isSupported = () => {
    try {
      const testKey = "telescope_tv";
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  const clear = () => {
    if (isSupported()) {
      storage.clear();
    } else {
      inMemoryStorage = {};
    }
  };

  const getItem = (name: string) => {
    const prefixedName = `${prefix}${name}`;

    if (isSupported()) {
      return storage.getItem(prefixedName);
    }
    if (inMemoryStorage.hasOwnProperty(prefixedName)) {
      return inMemoryStorage[prefixedName];
    }
    return null;
  };

  const key = (index: string) => {
    if (isSupported()) {
      return storage.key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  };

  const removeItem = (name: string) => {
    const prefixedName = `${prefix}${name}`;

    if (isSupported()) {
      storage.removeItem(prefixedName);
    } else {
      delete inMemoryStorage[prefixedName];
    }
  };

  const setItem = (name: string, value: string) => {
    const prefixedName = `${prefix}${name}`;

    if (isSupported()) {
      storage.setItem(prefixedName, value);
    } else {
      inMemoryStorage[prefixedName] = String(value);
    }
  };

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    length,
  };
};
