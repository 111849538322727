import { Stylesheet } from 'models/styles';
import { media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    navigation: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '15px 0',
      marginBottom: '20px',
      
      a: {
        textDecoration: 'none',
        padding: 0,
        margin: 0,
        transform: 'translateY(2px)'
      },
    },

    button: {
      margin: 0,
      fontSize: 14,
      ...styles.navigationStyles.links,
      ...styles.navigationStyles.links?.mobile,

      [media?.tablet]: {
        ...styles.navigationStyles.links?.tablet
      },

      [media?.desktop]: {
        ...styles.navigationStyles.links?.desktop
      },

      svg: {
        height: 15,
        width: 'auto',
        transform: 'translateY(1px)',
        ...styles.navigationStyles.icons,
        ...styles.navigationStyles.icons?.mobile,

        [media?.tablet]: {
          ...styles.navigationStyles.icons?.tablet
        },

        [media?.desktop]: {
          ...styles.navigationStyles.icons?.desktop
        }
      },

      i: {
        padding: '0 7px',
        display: 'inline-flex',
      },

      span: {
        padding: 0
      },

      '> span': {
        display: 'flex',
        alignItems: 'center',
      }
    },

    prevButton: {
      textAlign: 'left',
      
      '> span': {
        justifyContent: 'start',
      }
    },

    nextButton: {
      textAlign: 'right',

      '> span': {
        justifyContent: 'end',
      }
    }
  }
}
