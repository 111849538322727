

import { createSlice } from '@reduxjs/toolkit';

type User = {
  method: string,
  user_id: string,
  email?: string,
  first_name?: string,
  last_name?: string
} & Record<string, any>
export interface UserState {
  user: User,
  authorization?: {
    token: string
  },
  isAuthorized: boolean,
  hasError: LoginErrorType
}

export type LoginErrorType = 'permissions' | 'generic' | 'email' | null;

const initialState: UserState = {
  user: {
    method: '',
    user_id: ''
  },
  authorization: undefined,
  isAuthorized: false,
  hasError: null,
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action) => {  
      state.user = action.payload.user;
      state.authorization = action.payload.authorization;
      state.isAuthorized = true;
    },
    resetUser: () => initialState,
    setLoginError: (state, action) => {
      state.hasError = action.payload;
    }
  }
});

export const { setUser, resetUser, setLoginError } = userSlice.actions;

export default userSlice.reducer;