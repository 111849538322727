/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
// Styles
import { stylesheet } from './style';
// Helpers
import * as models from 'models/index';
import { ICategory } from 'models/cms';
import { normalizeForUrl } from 'util/route-helpers';
import history from 'util/history';
import { withRouter } from 'react-router-dom';
import { normalizeStyles } from 'util/helpers';
// Components
import { DownArrowIcon } from 'util/icons';
import { Link } from 'react-router-dom';
// State
import { useCategories, useGlobalStyles } from 'store/cms';
import { useWidget } from '@telescope/cassini-hooks';
import { CategoryVote } from 'types';

type Props = {
  match: models.global.IGenericObject
}

function Dropdown(props: Props) {
  const { data: navigationData } = useWidget({
    select: (data: CategoryVote) => data.snapshot.snapshot_views.navigation
  });
  const { content, styles } = navigationData!;

  const categories = useCategories();

  const [ isOpen, setIOpen ] = useState(false);

  useEffect(() => {
    setIOpen(false);
  }, [ props.match.params.name ]);


  // Styles
  const globalStyles = useGlobalStyles();

  const style = stylesheet({
    globalStyles: globalStyles,
    dropdownStyles: normalizeStyles(styles.dropdown),
    isOpen: isOpen
  });

  const handleMenuToggle = () => {
    setIOpen((isOpen) => !isOpen);
  }

  return (
    <nav css={ style.dropdown } aria-label={content.dropdown_button}>
      <button css={ style.button } aria-haspopup="true" aria-expanded={isOpen} onClick={() => handleMenuToggle()} className="CategoriesDropdownBtn">
        <span> {content.dropdown_button} </span>
        <DownArrowIcon />
      </button>

      { isOpen &&
        <ul css={ style.menu }>
          { categories.map( ( category: ICategory ) => {
            const slug = normalizeForUrl( category.category_title );
            const isActive = category.active;
            const isSelected = props.match.params.name === slug;

            return (
              <li css={[ style.link, !isActive && style.disabled, isSelected && style.selected ]} 
                key={slug}>
                <Link aria-disabled={isActive ? 'false' : 'true'} 
                      to={{ pathname: isActive ? slug : '', search: history.location.search }}>
                  {category.category_title}
                </Link>
              </li>
            )
          } )}
        </ul>
      }
    </nav>
  );
}

export default withRouter( Dropdown );
