import { Stylesheet } from 'models/styles';
import { media, darkenColor } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    modal: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'block',
      overflow: 'auto',
      ...styles.modalStyles.overlay,
      ...styles.modalStyles.overlay?.mobile,

      [media?.tablet]: {
        ...styles.modalStyles.overlay?.tablet
      },

      [media?.desktop]: {
        ...styles.modalStyles.overlay?.desktop
      }
    },

    content: {
      position: 'relative',
      width: '100%',
      background: '#fff',
      maxWidth: '455px',
      ...styles.modalStyles.container,
      ...styles.modalStyles.container?.mobile,

      [media?.tablet]: {
        ...styles.modalStyles.container?.tablet
      },

      [media?.desktop]: {
        ...styles.modalStyles.container?.desktop
      }
    },

    container: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingTop: '30px',

      [media?.tablet]: {
        alignItems: 'center',
        padding: '50px',
      }
    },

    close: {
      padding: '6px 6px 4px 6px',
      top: 2,
      right: 2,
      position: 'absolute',
      zIndex: 1,

      [media?.tablet]: {
        top: -5,
        right: -35
      },

      svg: {
        ...styles.modalStyles.closeButton,
        ...styles.modalStyles.closeButton?.mobile,

        [media?.tablet]: {
          ...styles.modalStyles.closeButton?.tablet
        },

        [media?.desktop]: {
          ...styles.modalStyles.closeButton?.desktop
        }
      }
    }
  }
}
