/** @jsxImportSource @emotion/react */
import * as models from 'models/index';
import { stylesheet } from './style';
import { useGlobalStyles } from 'store/cms';
export interface ICountdownUnitProps {
  ariaHidden?: boolean;
  displayLabel: boolean;
  labels: models.global.IGenericObject;
  number: number;
  styles?: models.global.IGenericObject;
}

export function CountdownUnit (props: ICountdownUnitProps) {
  const { displayLabel, labels } = props;
  const label = props.number === 1 ? labels.singular : labels.plural;
  const ariaHidden = props.ariaHidden;

  const unit = props.number.toString().padStart(2, '0');

  const { app, ...globalStyles } = useGlobalStyles();

  const style = stylesheet( {
    globalStyles: globalStyles,
    countdownStyles: props.styles
  } );

  return (
    <div css={ style.countdown_unit } aria-hidden={ariaHidden? ariaHidden: false}>
      <span css={ style.countdown_number }> { unit } </span>

      { displayLabel &&
        <span css={ style.countdown_label }> {label} </span> }

    </div>
  );
}
