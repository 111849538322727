import { Stylesheet } from 'models/styles';
import { appMaxWidth, media } from 'util/style-variables';

export const stylesheet = (styles: Record<string,any>): Stylesheet => {
  return {
    footer: {
      backgroundColor: '#aaa',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      flexDirection: 'row',
      margin: '0 auto',
      padding: 0,
      maxWidth: styles.settings.displayBleed ? 'none' : appMaxWidth,
      width: '100%',
      minHeight: '40px',
      gap: '20px',
      ...styles.footerStyles.general,

      [ media.tablet ]: {
        minHeight: '60px',
        ...styles.footerStyles.general?.tablet
      },

      [ media.desktop ]: {
        ...styles.footerStyles.general?.desktop
      }
    },

    container: {
      display: 'flex',
      flexGrow: 1,
      height: '100%',
      maxWidth: appMaxWidth,
      margin: 'auto',
      width: '100%',
      padding: '20px 10px',
      flexDirection: 'column',

      [ media.tablet ]: {
        padding: '20px'
      }
    },

    linksContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      ...styles.footerStyles.linksContainer,
    },

    followLinksContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: '15px',
      ...styles.footerStyles.linksContainer,
    },

    copy: {
      ...styles.footerStyles.copy,
      ...styles.footerStyles.copy?.mobile,

      [ media?.tablet ]: {
        ...styles.footerStyles.copy?.tablet
      },

      [ media?.desktop ]: {
        ...styles.footerStyles.copy?.desktop
      },

      a: {
        color: styles.footerStyles.link.color
      },

      ...styles.footerStyles.copyContainer
    },

    footerLink: {
      margin: 0,
      padding: 0,
      textDecoration: 'none',
      display: 'inline-flex',
      ...styles.footerStyles.link,
      ...styles.footerStyles.link?.mobile,

      [ media?.tablet ]: {
        ...styles.footerStyles.link?.tablet
      },

      [ media?.desktop ]: {
        ...styles.footerStyles.link?.desktop
      }
    },

    followLink: {
      display: 'inline-block',
      padding: 0,
      margin: '0 10px',
      width: '35px',

      ':first-of-type': {
        marginLeft: '0'
      },

      ':last-of-type': {
        marginRight: '0'
      },

      img: {
        width: '100%'
      }
    },

    separator: {
      margin: '0 10px',
      ...styles.footerStyles.separator,

      ':last-child': {
        display: 'none'
      },

      [ media?.tablet ]: {
        ...styles.footerStyles.separator?.tablet
      },

      [ media?.desktop ]: {
        ...styles.footerStyles.separator?.desktop
      },
    }
  }
}
